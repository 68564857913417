import React from 'react';
import {connect} from 'react-redux';

import './tag-manager.less';
import * as tagActions from "../../../state/actions/tagActions";

import EditTag from './EditTag';

class TagManager extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            newTag: '',
            editTag: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.addTag = this.addTag.bind(this);
        this.editTag = this.editTag.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.editing !== this.props.editing){
            this.setState({editTag: null})
        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });

    }

    addTag(ev) {

        ev.preventDefault();

        this.props.addTag({name: this.state.newTag}).then(
            () => {
                this.setState({newTag: ''});
                this.props.getTags()
            }
        )

    }

    editTag(tagID) {

        const tag = this.props.tags.find(taggy => taggy._id === tagID);
        this.setState({editTag: tag});

    }

    render() {

        return (
            <div className="modal fade tag-manager" id="tags-modal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="exampleModalLabel">Manage Tags</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                !this.state.editTag &&
                                <div className='add-tag-container fade-in'>

                                    <div className='add-tag'>

                                        <form className='tag-form-inline' onSubmit={ev => {
                                            this.addTag(ev)
                                        }}>
                                            <input type='text' id='newTag' value={this.state.newTag}
                                                   placeholder='Start typing...'
                                                   className='form-control form-control-sm'
                                                   onChange={this.handleInputChange}/>
                                            <button className='btn btn-primary btn-sm'
                                                    disabled={this.state.newTag.length < 3}
                                                    type='submit'>Add tag
                                            </button>
                                        </form>

                                    </div>

                                    {
                                        !!this.props.tags.length &&
                                        <div className='tags-container fade-in'>

                                            {
                                                this.props.tags.map(
                                                    (tag, i) => (
                                                        <div key={i} className='tag-item btn btn-success btn-sm'
                                                             onClick={() => {
                                                                 this.editTag(tag._id)
                                                             }}>
                                                            <span className='tag-name'>{tag.name}</span>
                                                        </div>)
                                                )
                                            }

                                        </div>
                                    }

                                    {
                                        this.props.processingError &&
                                        <p className='alert alert-danger'>
                                            Sorry but an error occurred: {this.props.processingError}
                                        </p>
                                    }

                                    {
                                        this.props.processing &&
                                        <p className='alert alert-info'>Please wait...</p>
                                    }

                                    {
                                        !this.props.processing && !this.props.processingError && !this.props.tags.length &&
                                        <p className='alert alert-success'>You don't have any tags</p>
                                    }

                                </div>
                            }

                            {this.state.editTag && <EditTag cancelEdit={()=>{ this.setState({editTag: null}) }} tag={this.state.editTag} />}

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">close</button>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        tags: state.tags.tags,
        processing: state.tags.processing,
        processingError: state.tags.processingError
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        getTags: () => dispatch(tagActions.getTagsRequest()),
        addTag: newTag => dispatch(tagActions.addTagRequest(newTag))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TagManager)
