import React from 'react';
import {connect} from 'react-redux';
import * as tagActions from "../../../../state/actions/tagActions";

import './edit-tag.less';

class EditTag extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editName: false,
            newName: this.props.tag.name,
            requestSuccess: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.deleteTag = this.deleteTag.bind(this);

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });

    }

    updateTag(ev){

        ev.preventDefault();
        this.props.updateTag(this.props.tag._id, {name: this.state.newName}).then(
            ()=>{

                this.setState({requestSuccess: true});

                setTimeout(
                    ()=>{
                        this.setState({requestSuccess: false, editName: false});
                        this.props.cancelEdit();
                    }, 3000
                )

            }
        )

    }

    deleteTag(){

        this.props.deleteTag(this.props.tag._id).then(
            ()=>{

                this.setState({requestSuccess: true},
                    ()=>{
                        setTimeout(
                            ()=>{
                                this.setState({requestSuccess: false});
                                this.props.cancelEdit();
                            }, 3000
                        )
                    }
                )

            }
        );

    }

    render() {

        return (
            <div className='edit-tag-section'>

                <div className='edit-tag-container fade-in'>

                    {!this.state.editName && <span className='tag-name btn btn-sm btn-success' onClick={() => {
                        this.setState({editName: true})
                    }}>{this.props.tag.name}</span>}

                    {
                        this.state.editName &&
                        <div className='edit-tag-form'>

                            <form className='edit-tag-name form-inline fade-in' onSubmit={ev => {this.updateTag(ev)} }>
                                <input type='input' className='form-control form-control-sm' id='newName'
                                       onChange={this.handleInputChange} value={this.state.newName}/>
                                <button type='submit' className='btn btn-primary btn-sm'
                                        disabled={this.state.newName.length < 3}>save
                                </button>
                                <button className='btn btn-sm btn-default' onClick={() => {
                                    this.setState({editName: false})
                                }}>cancel
                                </button>
                            </form>

                        </div>

                    }

                    {
                        !this.state.editName &&
                        <div className="btn-group pull-right delete-tag-dropdown">

                            <button type="button" className="dropdown-toggle" data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    <span className="glyphicon glyphicon-trash pull-right icon-remove-tag"
                                          aria-hidden="true"></span>
                            </button>

                            <ul className="dropdown-menu tag-delete-confirm">
                                <li onClick={this.deleteTag}>confirm
                                </li>
                            </ul>

                        </div>
                    }

                </div>

                {
                    this.props.processingError &&
                    <p className='alert alert-danger'>
                        Sorry but an error occurred: {this.props.processingError}
                    </p>
                }

                {
                    this.props.processing &&
                    <p className='alert alert-info'>Please wait...</p>
                }

                {
                    this.state.requestSuccess &&
                    <p className='alert alert-info'>Request successful, please wait.</p>
                }

                <button className='btn btn-link cancel-edit' onClick={this.props.cancelEdit}>back</button>

                <div className='clearfix'></div>

            </div>
        )

    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        tags: state.tags.tags,
        processing: state.tags.processing,
        processingError: state.tags.processingError
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTag: (tagID, tagUpdate) => dispatch(tagActions.updateTagRequest(tagID, tagUpdate)),
        deleteTag: tagID => dispatch(tagActions.deleteTagRequest(tagID))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTag)

