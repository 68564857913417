
import Immutable from 'seamless-immutable';

import {ADD_SUBSCRIPTIONS} from '../actions/subscriptionActions';

function subscriptions(state = [], action){

    const immState = Immutable(state);

    switch(action.type){

        case ADD_SUBSCRIPTIONS:
            return action.subscriptions;

    }

    return immState;

}

export {subscriptions}

