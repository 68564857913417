import * as throbberActions from './throbberActions';

import axios from 'axios';
import config from '../../../config';

const ADD_SUBSCRIPTIONS = 'ADD_SUBSCRIPTIONS';

export {ADD_SUBSCRIPTIONS};

export function getSubscriptionsRequest() {

    return (dispatch, getState)=>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject) => {

                axios.get(config.server_url + '/api/v1/subscriptions').then(
                    ({data}) => {

                        dispatch(throbberActions.hideThrobber());

                        if (!data.success) {

                            dispatch(throbberActions.showError(data.msg));
                            return reject(data.msg);
                        }

                        dispatch(addSubscriptions(data.subscriptions));
                        resolve();

                    }
                ).catch(
                    (err) => {

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));

                        reject(err.message);

                    }
                );

            }
        );

    }

}

export function createSubscriptionRequest(stripetoken, stripeplanid) {

    return (dispatch, getState) => {

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject) => {

                axios.post(config.server_url + '/api/v1/subscription', {stripetoken, stripeplanid}).then(
                    ({data}) => {

                        dispatch(throbberActions.hideThrobber());

                        if (!data.success) {

                            dispatch(throbberActions.showError(`Sorry but an error occurred: ${data.msg}`));
                            return reject(data.msg);
                        }

                        resolve(data);

                    }
                ).catch(
                    (err) => {

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(`Sorry but an error occurred: ${err.message}`));

                        reject(err.message);

                    }
                );

            }
        );

    }

}

export function deleteSubscriptionRequest(subscriptionId) {

    return (dispatch, getState) => {

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject) => {

                axios.delete(`${config.server_url}/api/v1/subscription/${subscriptionId}`).then(
                    ({data}) => {

                        if (!data.success) {

                            dispatch(throbberActions.showError(`Sorry but an error occurred: ${data.msg}`));
                            return reject(data.msg);
                        }

                        dispatch(throbberActions.hideThrobber('Subscription canceled successfully'));
                        resolve(data);

                    }
                ).catch(
                    (err) => {

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(`Sorry but an error occurred: ${err.message}`));

                        reject(err.message);

                    }
                );

            }
        );

    }

}

// Synchronous methods
export function addSubscriptions(subscriptions) {

    return {
        type: ADD_SUBSCRIPTIONS,
        subscriptions
    }

}


