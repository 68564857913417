
import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import 'bootstrap';

require('summernote/dist/summernote.css');

import './less/variables.less';
import './less/style.less';

import App from './App';

import melonStore from './state/store';

// Make store available to all descendants
render(

    <Provider store={melonStore}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>

    ,
    document.getElementById('root')
);