import React from 'react';

import './flashcard-test.less';

export default class FlashcardTest extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            testing: false,
            flashcards: this.props.flashcards,
            questionIndex: 0,
            showQuestion: true
        };

        this.toggleTest = this.toggleTest.bind(this);
        this.randomiseFlashcards = this.randomiseFlashcards.bind(this);

    }

    componentDidMount() {
        if(typeof this.props.testing !== 'undefined'){
            this.setState({testing: this.props.testing})
        }
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(JSON.stringify(prevProps.flashcards) !== JSON.stringify(this.props.flashcards)){
            this.setState(
                {
                    testing: typeof this.props.testing !== 'undefined' ? this.props.testing : false,
                    flashcards: this.props.flashcards,
                    questionIndex: 0,
                    showQuestion: true
                }
            )
        }

        if(prevProps.testing !== this.props.testing)
            this.setState({testing: this.props.testing});

    }

    toggleTest() {

        this.setState({testing: !this.state.testing});

    }

    nextQuestion(forward = true) {

        const length = this.state.flashcards.length;
        let newIndex = this.state.questionIndex;

        if (forward) {

            if (this.state.questionIndex === (length - 1)) {
                newIndex = 0;
            } else {
                newIndex++;
            }

        } else {

            if (this.state.questionIndex === 0) {
                newIndex = length - 1;
            } else {
                newIndex--;
            }

        }

        this.setState({questionIndex: newIndex, showQuestion: true});

    }

    randomiseFlashcards() {

        this.setState(
            {
                flashcards: this.state.flashcards.sort(() => 0.5 - Math.random()),
                questionIndex: 0,
                showQuestion: true
            }
        );

    }

    render() {

        if (!this.state.testing || !this.state.flashcards.length) {

            if(this.props.hideButton)
                return null;

            return (
                <button className="btn flashcards-start-test" onClick={this.toggleTest}>test me!</button>
            )

        }

        // Testing
        return (

            <div className="flashcard-test-background" onClick={this.toggleTest}>

                <div className="flashcard-test-carousel" onClick={(ev) => {
                    ev.stopPropagation()
                }}>

                    <div className='flashcards-top'>

                        <span className='flashcard-number'>{this.state.flashcards.length} flashcards</span>

                        <span onClick={this.toggleTest} className="glyphicon glyphicon-remove stop-test-icon"
                              aria-hidden="true"></span>

                    </div>

                    <div className="flashcard-test-container">

                        <div className="flashcard-container">

                            <span
                                className={'flashcard-next-prev glyphicon glyphicon-chevron-left' + (this.state.flashcards.length > 1 ? '' : ' element-hidden')}
                                onClick={() => {
                                    this.nextQuestion(false)
                                }}></span>

                            <div className="flashcard fade-in"
                                 onClick={() => { this.setState({showQuestion: !this.state.showQuestion}) }}>

                                {this.state.showQuestion &&
                                <div className='flashcard-text fade-in'>
                                    {this.state.flashcards[this.state.questionIndex].question}
                                </div>
                                }

                                {!this.state.showQuestion &&
                                <div className='flashcard-text fade-in'>
                                    {this.state.flashcards[this.state.questionIndex].answer}
                                </div>
                                }

                            </div>

                            <span
                                className={'flashcard-next-prev glyphicon glyphicon-chevron-right' + (this.state.flashcards.length > 1 ? '' : ' element-hidden')}
                                onClick={() => {
                                    this.nextQuestion(true)
                                }}></span>

                        </div>

                    </div>

                    <div className="randomise-flashcards" onClick={this.randomiseFlashcards}>
                        <span className="glyphicon glyphicon-random"></span>
                    </div>

                </div>
            </div>
        )

    }


}

