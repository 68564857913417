
import React from 'react';
import axios from 'axios';
import config from '../../../../config';

import {connect} from 'react-redux';

import {getInvoicesRequest} from "../../../state/actions/invoiceActions";

class ListInvoices extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            invoiceError: ''
        };

        this.cancelInvoice = this.cancelInvoice.bind(this);
        this.payInvoice = this.payInvoice.bind(this);

    }

    componentDidMount() {

        if(!this.props.invoices)
            this.props.getInvoices();
    }

    async cancelInvoice(invoiceid){

        if(!confirm('Delete invoice?'))
            return;

        try{

            axios.defaults.headers.common['x-access-token'] = localStorage.getItem('melon-token');

            const {data} = await axios.delete(config.server_url + '/api/v1/invoices/' + invoiceid);

            if(!data.success)
                throw new Error(data.msg);

            this.props.getInvoices();

        }catch (err) {

            console.error(err.message);
            this.setState({invoiceError: err.message});

            setTimeout(
                ()=>{
                    this.setState({invoiceError: ''});
                }, 3000
            )

        }

    }

    async payInvoice(invoiceid){



    }

    render(){

        if(!this.props.invoices)
            return null;

        if(!this.props.invoices.length)
            return <p className='alert alert-info'>You do not have any unpaid invoices</p>

        return (
            <div className='unpaid-invoice-list col-md-8 col-md-offset-2'>

                <h2>Your unpaid invoices</h2>

                <div className='invoice-blurb'>

                    <p>You will have unpaid invoices if:</p>

                    <ul>
                        <li>Your subscription was renewed but your payment failed</li>
                        <li>Your subscription was renewed but your payment requires further authorisation from you</li>
                        <li>You began the payment process for a membership for a subscription but did not complete it</li>
                    </ul>

                    <p>You can easily cancel an invoice by clicking on the 'cancel' link against the invoice below</p>

                </div>

                <div className='table-responsive'>

                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                        {
                            this.props.invoices.map(
                                (invoice, i) => (
                                    <tr key={i}>
                                        <td>{invoice.description}</td>
                                        <td>{invoice.amount}</td>
                                        <td>{invoice.status}</td>
                                        <td>
                                            <button
                                                className='btn btn-sm btn-success'
                                                style={{float:'right'}}
                                                onClick={()=>{ this.payInvoice(invoice.id) }}>pay now</button>
                                            <button
                                                onClick={()=>{ this.cancelInvoice(invoice.id) }}
                                                className='btn btn-sm btn-danger'
                                                style={{marginRight: 5, float:'right'}}>cancel</button>
                                        </td>
                                    </tr>
                                )
                            )
                        }

                        </tbody>
                    </table>

                </div>

                { this.state.invoiceError && <p className='alert alert-danger'>sorry but there was an error with this request: { this.state.invoiceError }</p> }

            </div>
        )

    }
}

const mapStateToProps = state =>{
    return{
        invoices: state.invoices
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getInvoices: ()=>{dispatch(getInvoicesRequest())}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListInvoices)
