import React from 'react';

import {connect} from 'react-redux';

import * as flashcardSetActions from '../../../state/actions/flashcardSetActions';

class EditFlashcardSetName extends React.Component {

    constructor(props) {

        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateFlashcardSet = this.updateFlashcardSet.bind(this);

        this.state = {
            newName: props.flashcardSet.name
        }

    }

    componentDidMount(){

        document.getElementById('editFlashcardSetInput').focus();
        document.getElementById('editFlashcardSetInput').select();

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    updateFlashcardSet(ev) {

        ev.preventDefault();
        this.props.updateFlashcardSetRequest(this.props.flashcardSet._id, {name: this.state.newName}).then(
            ()=>{
                this.props.finished();
            }
        )


    }

    render() {

        return (

            <form onSubmit={this.updateFlashcardSet}>

                <div className="form-inline edit-category-control">

                    <div className="form-group">

                        <input
                            id="editFlashcardSetInput"
                            type="text"
                            placeholder="Type new name"
                            name="newName"
                            value={this.state.newName}
                            onBlur={this.updateFlashcardSet}
                            onChange={this.handleInputChange}
                            className="form-control"
                        />

                    </div>

                </div>


            </form>

        )

    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber,
        flashcardSet: state.flashcardSet,
        user: state.user
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.showThrobber
        updateFlashcardSetRequest : (flashcardSetID, updatedFlashcardSet)=> dispatch(flashcardSetActions.updateFlashcardSetRequest(flashcardSetID, updatedFlashcardSet))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFlashcardSetName);
