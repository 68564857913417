

import React from 'react';
import { connect } from 'react-redux';

import * as throbberActions from '../../state/actions/throbberActions';

class Throbber extends React.Component{

    constructor(props){

        super(props)

    }


    render(){

        if(this.props.throbber.showThrobber){

            if(this.props.throbber.throbberType == 'INFO'){

                return (
                    <div className="throbber throbber-info">

                        {
                            this.props.throbber.showLoading &&
                            <img className="throbber-loading-gif" src="https://app.studymelon.com/img/calendar-loading.gif" />
                        }

                        <span className="throbber-message">{this.props.throbber.message || 'hello' }</span>
                    </div>
                )

            }

            if(this.props.throbber.throbberType == 'ERROR'){

                return (
                    <div className="throbber throbber-error">
                        <span className="throbber-message">{this.props.throbber.message }</span>
                        <button className="btn btn-default" onClick={this.props.hideThrobber}>OK</button>
                    </div>
                )

            }

        }

        return null;

    }


}

const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.showThrobber
        showThrobber : () => dispatch(throbberActions.showThrobber()),
        showError: () => dispatch(throbberActions.showError()),
        hideThrobber : () => dispatch(throbberActions.hideThrobber()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Throbber)