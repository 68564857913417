import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {getInvoicesRequest} from "../../../state/actions/invoiceActions";

import './unpaid-invoices.less';

class UnpaidInvoices extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

        this.props.getInvoices()

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        console.log(this.props.invoices);

    }

    render() {

        if (!this.props.invoices || !this.props.invoices.length)
            return null;

        return (
            <div className='unpaid-invoices'>
                You have unpaid invoices. <Link to='/account/subscriptions'>Please click here to manage your invoices</Link>
            </div>
        )

    }

}

const mapStateToProps = state => {
    return {
        invoices: state.invoices
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getInvoices: () => {
            dispatch(getInvoicesRequest())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UnpaidInvoices)
