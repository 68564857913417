
import Immutable from 'seamless-immutable';

import {ADD_INVOICES, UPDATE_INVOICE} from '../actions/invoiceActions';

function invoices(state = [], action){

    const immState = Immutable(state);

    switch(action.type){

        case ADD_INVOICES:
            return action.invoices;

        case UPDATE_INVOICE:
            return immState.map(
                invoice => {

                    if(invoice.id === action.invoiceid){
                        return {...invoice, ...action.invoiceUpdates};
                    }

                    return invoice;
                }
            )

    }

    return immState;

}

export {invoices}

