import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import * as worksheetActions from '../../../state/actions/worksheetActions';

import EditWorksheetName from '../EditWorksheetName';
import LanguageChooser from '../../note/acenotes/LanguageChooser.jsx';

import * as auth from "../../../lib/userAuth";

// Languages for ace editor
const languages = [
    'c_cpp',
    'csharp',
    'css',
    'html',
    'javascript',
    'java',
    'json',
    'less',
    'php',
    'python',
    'ruby',
    'sass',
    'scss',
    'typescript'
];

import './worksheet-display.less';

class WorksheetDisplay extends React.Component {

    constructor(props) {

        super(props);

        this.state = {};
        this.handleInputChange = this.handleInputChange.bind(this);

        this.addNote = this.addNote.bind(this);
        this.addCode = this.addCode.bind(this);
        this.deleteWorksheet = this.deleteWorksheet.bind(this);
        this.toggleLock = this.toggleLock.bind(this);

        // Default language
        this.state = {
            language: 'javascript'
        }

    }

    componentDidMount() {

        // Redirect if the user is not authenticated
        if (!auth.getUserInfo()) {

            return this.props.history.push('/login');

        }

        // Retrieve worksheet and add it to the props
        this.props.getWorksheet(this.props.match.params.worksheetid);

    }

    componentDidUpdate() {

        // Stop bootstrap dropdown menu from closing on code language select
        $(document).on('click', '.dropdown-menu', function (e) {
            e.stopPropagation();
        });

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    addNote(ev) {

        ev.preventDefault();
        this.props.addNoteToWorksheet(this.props.match.params.worksheetid, {type: 'html'}).then(
            () => {
                this.setState(this.state);
            }
        );

    }

    addCode(ev) {

        ev.stopPropagation();
        ev.preventDefault();

        this.props.addNoteToWorksheet(this.props.match.params.worksheetid, {
            type: 'ace',
            acelanguage: this.state.language
        }).then(
            () => {

                // Manually close the language dropdown
                $('.add-ace-dropdown.open').removeClass('open');
                this.setState(this.state);
            }
        );

    }

    deleteWorksheet(ev) {

        ev.preventDefault();
        this.props.deleteWorksheet(this.props.worksheet._id).then(
            () => {
                this.props.history.push('/');
            }
        )

    }

    toggleLock(ev, lockWorksheet = true) {

        ev.preventDefault();
        this.props.updateWorksheet(this.props.worksheet._id, {locked: lockWorksheet});

    }

    render() {

        if (!this.props.worksheet) {
            return (
                <p className='alert alert-info'>Sorry but this worksheet was not found</p>
            )
        }

        return (

            <div className="col-md-10 col-md-offset-1 fade-in">

                <div className="worksheet-display">

                    {
                        this.state.editName ?

                            <EditWorksheetName finished={() => {
                                this.setState({editName: false})
                            }} worksheet={this.props.worksheet}/> :

                            <h4 className={this.props.worksheet.locked ? 'worksheet-header' : 'worksheet-header name-editable'}>
                            <span className="edit-worksheet-click"
                                  onClick={() => {
                                      !this.props.worksheet.locked && this.setState({editName: true})
                                  }}>{this.props.worksheet.name}</span>
                            </h4>
                    }


                    <div className="notes-container">

                        {
                            this.props.worksheet.notes && this.props.worksheet.notes.length ?

                                this.props.worksheet.notes.map((note, i) => {

                                        return <LanguageChooser key={i} note={note} worksheetLocked={this.props.worksheet.locked}/>

                                    }
                                )

                                : <p className="alert alert-info">
                                    This worksheet has no notes. Add a note using the buttons below.
                                </p>


                        }

                        <div className="clearfix"></div>

                    </div>

                    <div className="worksheet-controls">

                        <button disabled={this.props.worksheet.locked} className="btn btn-primary"
                                onClick={this.addNote}>add note
                        </button>

                        {/*

                            Not doing code snippets atm

                        <div className="btn-group add-ace-dropdown">

                            <button disabled={this.props.worksheet.locked}
                                    type="button"
                                    className="dropdown-toggle btn btn-success"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                Add code
                            </button>

                            <div className='dropdown-menu language-select-dropdown'>

                                <form onSubmit={(ev)=>{ this.addCode(ev) }} className='form-inline'>

                                    <div className='form-group'>

                                        <select className='form-control language-select'
                                                name='language'
                                                value={this.state.language}
                                                onChange={this.handleInputChange}>

                                            {languages.map((language, i) => <option key={i}
                                                                                    value={language}>{language}</option>)}

                                        </select>

                                        <button type='submit' className='btn btn-primary'>Add code</button>

                                    </div>

                                </form>


                            </div>

                        </div>*/}


                        <Link className="btn btn-default" to="/">go back</Link>

                        {
                            this.props.worksheet.locked ?

                                <button onClick={() => {
                                    this.toggleLock(event, false)
                                }} className="btn btn-danger">Unlock worksheet</button> :
                                <button onClick={() => {
                                    this.toggleLock(event, true)
                                }} className="btn btn-success">Lock worksheet</button>

                        }


                        <div className="btn-group pull-right worksheet-delete-dropdown">

                            <button disabled={this.props.worksheet.locked}
                                    type="button"
                                    className="dropdown-toggle btn btn-danger"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                Delete worksheet
                            </button>

                            <ul className="dropdown-menu worksheet-delete-confirm">
                                <li onClick={this.deleteWorksheet}>delete this worksheet and all notes</li>
                            </ul>

                        </div>
                    </div>

                    <div className="clearfix"></div>

                </div>

            </div>

        )


    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber,
        user: state.user,
        worksheet: state.worksheet
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        getWorksheet: (worksheetID) => dispatch(worksheetActions.getWorksheetRequest(worksheetID)),
        addNoteToWorksheet: (worksheetID, newNote) => dispatch(worksheetActions.addNoteToWorksheetRequest(worksheetID, newNote)),
        updateWorksheet: (worksheetID, updatedWorksheet) => dispatch(worksheetActions.updateWorksheetRequest(worksheetID, updatedWorksheet)),
        deleteWorksheet: (worksheetID) => dispatch(worksheetActions.deleteWorksheetRequest(worksheetID))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorksheetDisplay));


