

import axios from 'axios';
import config from '../../../config';

const ADD_INVOICES = 'ADD_INVOICES',
    UPDATE_INVOICE = 'UPDATE_INVOICE';

export {ADD_INVOICES, UPDATE_INVOICE};

// Get all the user's invoices from Stripe.
export function getInvoicesRequest() {

    return (dispatch, getState)=>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        return new Promise(
            (resolve, reject) => {

                axios.get(config.server_url + '/api/v1/invoices/true').then(
                    ({data}) => {

                        if (!data.success) {

                            return reject(data.msg);
                        }

                        dispatch(addInvoices(data.invoices));
                        resolve();

                    }
                ).catch(
                    (err) => {

                        console.error('ALARRRRMMM!! ' + err.message);
                        reject(err.message);

                    }
                );

            }
        );

    }

}


export function payInvoiceRequest(stripetoken, invoiceid) {

    return (dispatch, getState) => {

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        return new Promise(
            (resolve, reject) => {

                axios.put(`${config.server_url}/api/v1/invoices/pay/${invoiceid}`, {stripetoken}).then(
                    ({data}) => {

                        if (!data.success) {
                            return reject(data.msg);
                        }

                        resolve(data);

                    }
                ).catch(
                    (err) => {

                        console.error('ALARRRRMMM!! ' + err.message);
                        reject(err.message);

                    }
                );

            }
        );

    }

}

// Synchronous methods
export function addInvoices(invoices) {

    return {
        type: ADD_INVOICES,
        invoices
    }

}

export function updateInvoice(invoiceid, invoiceUpdates) {
    return{
        type: UPDATE_INVOICE,
        invoiceid,
        invoiceUpdates
    }
}


