import axios from "axios";
import * as throbberActions from "./throbberActions";
import config from "../../../config";
import {addFlashcardSet} from "./flashcardSetActions";

// This is for creating a list of flashcardsets. For operations on a single flashcardset set the flashcardSetActions

const ADD_FLASHCARDSETS = 'ADD_FLASHCARDSETS',     // Add flashcardsets to the list
    ADD_FLASHCARDSET_TO_LIST = 'ADD_FLASHCARDSET_TO_LIST',
    DELETE_FLASHCARDSET = 'DELETE_FLASHCARDSET', // Delete all flashcardsets from the list
    DELETE_FROM_FLASHCARDSET = 'DELETE_FROM_FLASHCARDSET';  // Delete one or most flashcardsets from the list

export {ADD_FLASHCARDSETS, ADD_FLASHCARDSET_TO_LIST, DELETE_FLASHCARDSET, DELETE_FROM_FLASHCARDSET}

export function getFlashcardsByCategoryRequest(categories){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.get(`${config.server_url}/api/v1/flashcardset/`, {params: {categories}}).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success){

                            dispatch(throbberActions.showError(response.msg));
                            return reject(response.msg);
                        }

                        dispatch(deleteFlashcardsetsList());
                        dispatch(addFlashcardsetsToList(response.flashcardsets));

                        resolve({success: true});

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));

                        resolve({success: false})

                    }
                );

            }
        );



    }

}

export function addFlashcardsetsToList(flashcardsets){

    return{
        type: ADD_FLASHCARDSETS,
        flashcardsets
    }

}

export function addFlashcardsetToList(flashcardset){
    return {
        type: ADD_FLASHCARDSET_TO_LIST,
        flashcardset
    }
}

export function deleteFlashcardsetsList(){
    return {
        type: DELETE_FLASHCARDSET
    }
}

export function deleteFromFlashcardsetsList(flashcardsetid){
    return{
        type: DELETE_FROM_FLASHCARDSET,
        flashcardsetid
    }
}
