
import React from 'react';

import StripeUpdateCard from '../StripeUpdateCard';


class PayInvoice extends React.Component{

    constructor(props){

        super(props);

        this.state = {
            showModal: false
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(JSON.parse(JSON.stringify(prevProps.invoice)) !== JSON.parse(JSON.stringify(this.props.invoice))){

            if(this.props.invoice){

                $('.update-card-modal').modal();

            }

        }

    }

    render(){

        if(!this.props.invoice)
            return null;

        return (
            <div className="modal fade update-card-modal" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>

                            <h4 className="modal-title" ng-if="invoice">Pay invoice</h4>

                        </div>

                        <div className="modal-body">

                            <p>You are paying an invoice for {this.props.invoice.description}</p>

                            <StripeUpdateCard invoice={this.props.invoice} />

                            <div className="clearfix"></div>

                        </div>

                    </div>
                </div>
            </div>
        )

    }

}

export default PayInvoice;