import React from 'react';

import {connect} from 'react-redux';

import * as throbberActions from '../../../state/actions/throbberActions';
import * as categoryActions from '../../../state/actions/categoryActions';

import  axios from 'axios/index';
import config from '../../../../config';

class AddCategory extends React.Component {

    constructor(props) {

        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.addCategory = this.addCategory.bind(this);

        this.state = {
            newCategory: ''
        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    addCategory(ev) {

        ev.preventDefault();
        this.props.addCategoryRequest({name: this.state.newCategory});
        this.setState({newCategory: ''});

    }

    render() {

        return (

            <form className="add-category form-inline" onSubmit={this.addCategory}>

                <div className="form-group">

                    <input
                        type="text"
                        minLength="3"
                        onChange={this.handleInputChange}
                        value={this.state.newCategory}
                        placeholder="Add new category"
                        className="form-control"
                        name="newCategory"/>

                </div>


            </form>


        )

    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber,
        categories: state.categories,
        user: state.user
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.showThrobber
        showThrobber: () => dispatch(throbberActions.showThrobber()),
        showError: (msg) => dispatch(throbberActions.showError(msg)),
        hideThrobber: () => dispatch(throbberActions.hideThrobber()),
        addCategoryRequest: (newCategory) => dispatch(categoryActions.addCategoryRequest(newCategory))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);
