
import React from 'react';
import {connect} from 'react-redux';
import { v4 as uuid } from 'uuid';

import TagManager from '../TagManager';
import * as tagActions from "../../../state/actions/tagActions";

class AddTag extends React.Component{

    constructor(props){

        super(props);

        this.state = {
            editing: ''
        };

        this.addTag = this.addTag.bind(this);
    }

    addTag(){
        this.props.getTags();
        this.setState({editing: uuid()}); // Force an update on the child component
        $('#tags-modal').modal();
    };

    render(){

        return[
            <button key='tag-button' className='btn btn-default' onClick={this.addTag}>
                manage tags
            </button>,
            <TagManager editing={this.state.editing} key={'tag-manager'} />
        ]


    }

}

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        getTags: ()=> dispatch(tagActions.getTagsRequest())
    }
};

export default connect(null, mapDispatchToProps)(AddTag);
