import React from 'react';
import debounce from 'debounce';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import PropTypes from 'prop-types';
import * as worksheetActions from "../../../state/actions/worksheetActions";

import './note-editor.less';

class NoteEditor extends React.Component {

    constructor(props) {

        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.noteChanged = this.noteChanged.bind(this);

        // Note onChange event will only update the server every 500ms
        this.noteChanged = debounce(this.noteChanged, 500);

        this.removeNote = this.removeNote.bind(this);
        this.changeWidth = this.changeWidth.bind(this);
        this.toggleNoteEdit = this.toggleNoteEdit.bind(this);

        this.state = Object.assign({}, {note: this.props.note}, {saving: false});

    }

    componentWillMount(){

        import('summernote').then(
            (results)=>{

            }
        )

    }

    componentDidUpdate() {

        if(this.props.note.editing){

            $('#textarea_' + this.props.note._id).summernote(
                {
                    airMode: true,
                    focus : true,
                    callbacks: {
                        onChange: this.noteChanged
                    }
                }
            );

        }else{

            $('#textarea_' + this.props.note._id).summernote('destroy');

            // Summernote destroy not removing its own markup
            $('.note-editor', '#sm-note-editor_' + this.props.note._id).remove()

        }


    }

    componentWillReceiveProps(newProps){

        this.setState({ note : newProps.note})

    }

    removeNote(ev) {

        ev.preventDefault();

        this.props.unsetNoteEditable();
        $('#textarea_' + this.props.note._id).summernote('destroy');

        this.props.removeNote(this.props.note._id);

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    // Update the note on the server when the content changes
    noteChanged(ev) {

        const noteHtml = $('#textarea_' + this.props.note._id).summernote('code');

        // Updates to the text in the textarea are immediate,
        // the user will receive an error message if there are network / server problems
        this.setState(
            {
                saving: true,
                note : {
                    html : noteHtml
                }
            }
        );

        const updatedNote = Object.assign({}, this.props.note, {html: noteHtml});

        this.props.updateNote(this.props.note._id, updatedNote).then(
            () => {

                // Show the 'saving' message for a second or it just becomes a meaningless blur.
                setTimeout(() => {
                    this.setState({saving: false});
                }, 1000)


            }
        );

    }

    changeWidth(increase = true){

        const cssClasses = [
            'col-md-4 col-sm-6 col-xs-12',
            'col-md-6 col-sm-6 col-xs-12',
            'col-md-12 col-sm-12 col-xs-12'
        ];

        const classIndex = cssClasses.indexOf(this.props.note.cssclass);

        if((increase && classIndex === cssClasses.length - 1) || !increase && classIndex === 0 ){
            return;
        }

        const newClass = cssClasses[increase ? classIndex + 1 : classIndex - 1],
            updatedNote = Object.assign({}, this.props.note, {cssclass : newClass});

        this.setState({saving : true});

        this.props.updateNote(this.props.note._id, updatedNote).then(
            () => {

                // Show the 'saving' message for a second or it just becomes a meaningless blur.
                setTimeout(() => {
                    this.setState({saving: false});
                }, 1000)


            }
        );

    }

    toggleNoteEdit(ev, editNote = true) {

        ev.preventDefault();

        // Set all notes to non editing state
        this.props.unsetNoteEditable();

        if(editNote){

            this.props.setNoteEditable(this.props.note._id);

        }

    }

    render() {

        return (

            <div id={'sm-note-editor_' + this.props.note._id} className={'sm-note-editor ' + this.props.note.cssclass + (this.props.note.editing ? ' editing-note' : '') }>

                {
                    this.props.note.editing ?
                        
                        <textarea
                            id={ 'textarea_' + this.props.note._id }
                            name='noteHTML'
                            placeholder='Start typing your note'
                            value={this.state.note.html}
                            onChange={this.noteChanged}
                        ></textarea> :

                        <p className={this.props.worksheetLocked ? 'note-text note-locked' : 'note-text note-editable'}
                           dangerouslySetInnerHTML={{__html: this.state.note.html || 'Type text, paste HTML, highlight to format'}}
                           onClick={(ev)=>{!this.props.worksheetLocked && this.toggleNoteEdit(ev, true)}}></p>

                }

                <div className={this.props.note.editing ? 'note-editor-controls controls-fade-in' : 'note-editor-controls'}>

                    <div className="pull-left note-change-width">

                        <span onClick={()=>{this.changeWidth(false)}}
                              className="width-icon glyphicon glyphicon-minus note-decrease-width" aria-hidden="true"></span>
                        <span onClick={()=>{this.changeWidth(true)}}
                              className="width-icon glyphicon glyphicon-plus note-increase-width" aria-hidden="true"></span>

                    </div>

                    {
                        this.state.saving ?
                            <span className='controls-saving'>saving...</span> :
                            <span className='controls-saving'>saved</span>
                    }

                    <div className='pull-right btn-group finish-edit'>

                        <button type="button"
                                className="icon-finish-edit btn btn-default"
                                onClick={()=>{this.toggleNoteEdit(event, false) }} >

                            <span className="glyphicon glyphicon-ok" aria-hidden="true"></span>

                        </button>

                    </div>

                    {/* Remove note dropdown */}
                    <div className="btn-group pull-right icon-remove-note">

                        <button type="button"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            <span className="glyphicon glyphicon-trash pull-right"
                                  aria-hidden="true"></span>
                        </button>

                        <ul className="dropdown-menu note-delete-confirm">
                            <li onClick={this.removeNote}>confirm</li>
                        </ul>

                    </div>

                    <div className="clearfix"></div>

                </div>

            </div>

        )

    }

}

NoteEditor.propTypes = {
    note: PropTypes.shape(
        {
            _id: PropTypes.string.isRequired
        }
    )
};

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        worksheet: state.worksheet
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        removeNote: (noteID) => dispatch(worksheetActions.removeNoteFromWorksheetRequest(noteID)),
        updateNote: (noteID, updatedNote) => dispatch(worksheetActions.updateNoteRequest(noteID, updatedNote)),
        setNoteEditable : (noteID) => dispatch(worksheetActions.setNoteEditable(noteID)),
        unsetNoteEditable : (noteID) => dispatch(worksheetActions.unsetNoteEditable())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoteEditor));


