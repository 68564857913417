import React from 'react';
import {connect} from 'react-redux';
import * as worksheetActions from '../../../state/actions/worksheetActions';

class EditWorksheetName extends React.Component {

    constructor(props) {

        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateWorksheet = this.updateWorksheet.bind(this);

        this.state = {
            newName: props.worksheet.name
        }

    }

    componentDidMount(){

        document.getElementById('editWorksheetInput').focus();
        document.getElementById('editWorksheetInput').select();

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    updateWorksheet(ev) {

        ev.preventDefault();
        this.props.updateWorksheetRequest(this.props.worksheet._id, {name: this.state.newName}).then(
            ()=>{
                this.props.finished();
            }
        )


    }

    render() {

        return (

            <form onSubmit={this.updateWorksheet}>

                <div className="form-inline edit-category-control edit-worksheet-name">

                    <div className="form-group">

                        <input
                            id="editWorksheetInput"
                            type="text"
                            placeholder="Type new name"
                            name="newName"
                            value={this.state.newName}
                            onBlur={this.updateWorksheet}
                            onChange={this.handleInputChange}
                            className="form-control"
                        />

                    </div>

                </div>


            </form>

        )

    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber,
        worksheet: state.worksheet,
        user: state.user
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        updateWorksheetRequest : (worksheetID, updatedWorksheet)=> dispatch(worksheetActions.updateWorksheetRequest(worksheetID, updatedWorksheet))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWorksheetName);
