
import React from 'react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {debounce} from '../../../lib/misc';
import * as searchActions from '../../../state/actions/searchActions';

import './search.less';
import SearchResults from "../SearchResults";

class SearchBar extends React.Component{

    constructor(props){

        super(props);

        this.state = {
            search : '',
            searchquery : ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        const classContext = this;

        this.debouncedSearch = debounce(500,
            () => {
                this.props.doSearch(classContext.state.searchquery);
            }
        );

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });

    }

    componentWillReceiveProps(props){

        if(props.searchQuery === ''){

            this.setState({searchquery : ''});

        }

    }

    handleSearch(ev){

        this.handleInputChange(ev);

        if(ev.target.value.length < 3)
            return;

        this.debouncedSearch();

    }

    render(){

        return (
            <div className='search-container'>

                <div className="search-bar">

                    <input
                        type="text"
                        id="searchquery"
                        value={this.state.searchquery}
                        placeholder="type 3 letters to search"
                        className="form-control melon-search"
                        onChange={this.handleSearch}/>

                    <span className="glyphicon glyphicon-remove clear-search" onClick={(ev) => {
                        this.setState({searchquery: ''});
                        this.props.clearSearch();
                    }} />

                </div>

                <SearchResults/>

            </div>
        )

    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        search: state.search,
        searchQuery : state.searchQuery
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        doSearch: (query) => dispatch(searchActions.updateSearchResultsRequest(query)),
        clearSearch: ()=> dispatch(searchActions.deleteSearchResults())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBar));
