import React from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

import * as throbberActions from '../../../state/actions/throbberActions';
import * as subActions from '../../../state/actions/subscriptionActions';
import * as invoiceActions from '../../../state/actions/invoiceActions';

import poweredByStripe from '../../../../public/img/powered-by-stripe.png';

import './stripe-create-membership.less';

class StripeCreateMembership extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            initialised: false,
            processing: false,
            paymentSuccess: false,
            stripeError: '',
            invalid: false,
            invoiceid: null,
            paymentIntentClientSecret: null
        };

        this.submitCard = this.submitCard.bind(this);

    }

    componentDidMount() {

        if (typeof Stripe === 'undefined')
            return this.props.showError('Sorry but an error has occurred: Stripe not loaded. Please reload the page and contact support@studymelon.com if this problem persists.');

        if (typeof this.props.stripeKey === 'undefined')
            return this.props.showError('Sorry but an error has occurred: Stripe public key not found. Please reload the page and contact support@studymelon.com if this problem persists.')

        this.stripe = Stripe(this.props.stripeKey);

        const elements = this.stripe.elements();

        this.stripeCard = elements.create('card');

        this.stripeCard.mount('#membership-card-element');

        this.stripeCard.addEventListener('change', function (event) {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });


    }

    submitCard(ev) {

        ev.preventDefault();

        this.props.showThrobber();
        this.setState({processing: true});

        // Some payment methods and cards may require further authentication. In all cases
        // retrieve a payment intent from the server. If the transaction requires no further authentication
        // send back the payment intent ID to confirm the transaction.
        this.stripe.createToken(this.stripeCard).then(
            result => {

                if (result.error) {
                    this.setState({processing: false, stripeError: `Sorry but an error occurred: unhandled status: ${result.error.message}`},
                        () => {

                            setTimeout(() => {
                                setState({stripeError: ''})
                            }, 3000)

                        }
                    );

                }

                if (this.state.invoiceid) {

                    this.setState({processing: false});

                    // There is an invoice that is unpaid and the payment method has been updated
                    return this.payInvoice(result.token, this.state.invoiceid)
                }

                this.createMembership(result.token)

            }
        );

    }

    createMembership(token = null) {

        this.props.createSub(token, this.props.plan.id).then(
            (response) => {

                this.setState({processing: false});

                if (response.success && !response.requires_action) { // Invoice has been paid.

                    this.setState({paymentSuccess: true},
                        () => {

                            this.props.getInvoices();
                            setTimeout(() => {
                                this.props.history.push('/')
                            }, 4000)

                        }
                    );

                    return;
                }

                if (response.requires_action) {   // Usually this means 2fa

                    this.setState(
                        {
                            invoiceid: response.invoiceid,
                            paymentIntentClientSecret: response.payment_intent_client_secret
                        },
                        () => {

                            this.handleSCA(response.payment_intent_client_secret)

                        }
                    );

                    return;
                }

                this.setState({stripeError: `Sorry but an error occurred: unhandled status: ${response.status}`},
                    () => {

                        setTimeout(() => {
                            setState({stripeError: ''})
                        }, 3000)

                    }
                );


            }
        ).catch(
            err => {
                console.log('blah');
                console.error(err);

                this.setState({processing: false});

            }
        );


};

    handleSCA(clientSecret) {

        this.setState({processing: true});

        this.stripe.handleCardPayment(clientSecret, this.stripeCard).then(
            response => {

                this.setState({processing: false});

                if (response.error) {

                    this.setState({stripeError: `Sorry but an error occurred: ${response.error.message}`},
                        ()=>{
                            setTimeout(
                                ()=>{

                                    this.props.getInvoices();
                                    this.setState({stripeError: ''})
                                }, 3000
                            )
                        }
                    );

                    return;
                }

                // Unless there has been a problem, the invoice will now have been paid.
                // Also a webhook will have been generated that will have been sent to the backend to activate the membership
                // Check the backend for any problems?
                this.setState({paymentSuccess: true},
                    ()=>{

                        setTimeout(
                            ()=>{
                                this.props.history.push('/')
                            },4000
                        )

                    }
                );


            }).catch(
            err => {

                console.error(err);
                this.setState({stripeError: `Sorry but an error occurred: ${err.message}`},
                    ()=>{
                        setTimeout(
                            ()=>{
                                this.setState({stripeError: ''})
                            }, 4000
                        )
                    }
                );

            }
        );

    };

    payInvoice(stripetoken, invoiceid) {

        this.setState({processing: true});

        this.props.payInvoiceRequest(stripetoken, invoiceid).then(
            (response)=>{

                this.setState({processing: false});

                if(!response.success){
                    this.setState({stripeError: response.msg},
                        ()=>{
                            setTimeout(
                                ()=>{
                                    this.setState({stripeError: ''})
                                },3000
                            )
                        }
                    )
                }

                if (response.success && !response.requires_action) {

                    this.setState({paymentSuccess: true},
                        ()=>{

                            setTimeout(
                                ()=>{
                                    this.props.history.push('/')
                                },4000
                            )

                        }
                    );

                }

                if (response.requires_action) {
                    return this.handleSCA(response.payment_intent_client_secret)
                }

                this.setState({stripeError: `Sorry but an error occurred - unhandled status: ${response.status}`},
                    ()=>{
                        setTimeout(
                            ()=>{
                                this.setState({stripeError: ''})
                            },3000
                        )
                    }
                );

            }
        ).catch(
            err => {

                this.setState({processing: false});
                console.error(err.message);

                this.setState({stripeError: `Sorry but an error occurred - unhandled status: ${err.message}`},
                    ()=>{
                        setTimeout(
                            ()=>{
                                this.setState({stripeError: ''})
                            },3000
                        )
                    }
                );

            }
        );

    };


    render() {

        return (
            <div className="stripe-card-payment stripe-membership-payment">

                <div className="enter-card-details">

                    <form onSubmit={this.submitCard}>

                        <div className='card-details'>

                            <label htmlFor="membership-card-element">
                                Please enter your credit or debit card details.
                            </label>

                            {/*Container to be populated by Stripe*/}
                            <div id="membership-card-element">

                            </div>

                            {/*Used to display form errors from Stripe*/}
                            <div id="card-errors" role="alert"></div>

                            {this.state.activationSuccess &&
                            <div className="payment-success alert alert-success membership-purchase-success">
                                Your membership has been activated, thank you very much for your payment.
                            </div>
                            }

                            {
                                this.state.stripeError &&
                                <p className="processing-message alert alert-danger"
                                   style={{
                                       padding: '3px 0',
                                       textAlign: 'center',
                                       marginBottom: 15
                                   }}>
                                    { this.state.stripeError }
                                </p>
                            }

                            {
                                this.state.processing &&
                                <p className="processing-message alert alert-info"
                                   style={{
                                       padding: '3px 0',
                                       textAlign: 'center',
                                       marginBottom: 15
                                   }}>
                                    Please be patient. Security checks on your card may take a few moments.
                                </p>
                            }

                            {
                                this.state.paymentSuccess &&
                                <p className="payment-success alert alert-success"
                                   style={{

                                       textAlign: 'center',
                                       marginBottom: 15
                                   }}>
                                    Your payment has been successful and you will receive confirmation by email. Thank
                                    you very much for your payment!
                                    You will now be redirected to the dashboard.
                                </p>
                            }

                            <a className="pull-left" target="_blank" href="https://stripe.com">
                                <img style={{maxWidth: '93%'}} src={poweredByStripe}/>
                            </a>

                            {
                                this.state.processing &&
                                <button type="submit" disabled className="btn btn-primary pull-right">Please
                                    wait...</button>
                            }

                            {
                                !this.state.processing &&
                                <button type="submit" ng-if="!processing" className="btn btn-primary pull-right">Submit
                                    Payment</button>
                            }

                            <Link to='/' ng-if="!processing" type="button" className="btn btn-default pull-right"
                                    style={{marginRight: 5}} >Cancel
                            </Link>

                            {
                                this.state.invalid &&
                                <p className="alert alert-danger">
                                    Sorry but there is a configuration error and this payment cannot be processed.
                                    Please
                                    contact
                                    support@smoothbook.co if
                                    this problem persists.
                                </p>
                            }


                        </div>


                    </form>

                </div>

            </div>
        )

    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        throbber: state.throbber,
        charging: state.settings.charging,
        invoices: state.invoices,
        user: state.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showThrobber: () => dispatch(throbberActions.showThrobber()),
        showError: msg => dispatch(throbberActions.showError(msg)),
        hideThrobber: () => dispatch(throbberActions.hideThrobber()),
        createSub: (stripeToken, plan) => dispatch(subActions.createSubscriptionRequest(stripeToken, plan)),
        getInvoices: ()=> dispatch(invoiceActions.getInvoicesRequest()),
        payInvoiceRequest: (stripeToken, invoiceid) => dispatch(invoiceActions.payInvoiceRequest(stripeToken, invoiceid))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StripeCreateMembership))
