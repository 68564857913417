
import React from 'react';
import { connect } from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

import * as throbberActions from '../../../state/actions/throbberActions';
import * as userActions from '../../../state/actions/userActions';

import SocialLoginContainer from '../../../components/socialLogin/SocialLoginContainer';

import  axios from 'axios/index';
import config from '../../../../config.js';

class Login extends React.Component{

    constructor(props){

        super(props);

        this.state = {
            email : '',
            password : '',
            loginSuccessful : false,
        };

        this.login = this.login.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    login(ev){

        ev.preventDefault();
        this.props.showThrobber();

        const user = this.state;

        axios.post(config.server_url + '/auth/login', { user : user}).then(
            (reply)=>{

                this.props.hideThrobber();

                const response = reply.data;

                if(!response.success){
                    this.props.showError(response.msg);
                    return;
                }


                this.props.userLoggedIn(response.user);

                // Store JWT token
                localStorage.setItem('melon-token', response.token);
                this.props.history.push('/');

            }
        ).catch(
            (err)=>{

                console.error(err.message);
                this.props.showError(err.message);
            }
        )

    }

    render(){

        return (

            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">

                <h2>Log into Studymelon</h2>

                <SocialLoginContainer />

                <form onSubmit={this.login}>

                    <div className="form-group">

                        <label>Email</label>

                        <input
                            type="email"
                            required
                            className="form-control"
                            name="email"
                            onChange={this.handleInputChange}
                            value={this.state.email} />

                    </div>

                    <div className="form-group">

                        <label>Password</label>

                        <input
                            type="password"
                            required
                            className="form-control"
                            name="password"
                            onChange={this.handleInputChange}
                            value={this.state.password} />

                    </div>

                    { this.state.loginSuccessful &&

                        <p className="alert alert-info">
                            Login successful. You will now be redirected...
                        </p>

                    }

                    <div className="form-group">

                        <Link to='/forgot-password'>forgot password</Link>

                        <button type="submit" className="btn btn-primary pull-right">submit</button>

                        <div className="clearfix"></div>

                    </div>

                    <div className="form-group">

                        <Link className="alert alert-success cursor-pointer btn-full-width" to='/register'>
                            Click here if you have not registered
                        </Link>

                    </div>

                </form>

            </div>

        )

    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.showThrobber
        showThrobber : () => dispatch(throbberActions.showThrobber()),
        showError: (msg) => dispatch(throbberActions.showError(msg)),
        hideThrobber : () => dispatch(throbberActions.hideThrobber()),
        userLoggedIn : (user)=> dispatch(userActions.userLoggedIn(user))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
