import React from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

import moment from 'moment-timezone';

moment.tz.setDefault('America/Los_Angeles');

import PayInvoice from '../PayInvoice';

import {deleteSubscriptionRequest, getSubscriptionsRequest} from "../../../state/actions/subscriptionActions";
import {getSettingsRequest} from '../../../state/actions/settingsActions';
import {getInvoicesRequest} from "../../../state/actions/invoiceActions";

import './list-subscriptions.less';
import axios from "axios";
import config from "../../../../config";

class ListSubscriptions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            charging: null,
            displayRows: [],
            invoiceToPay: null
        };

        this.toggleHistory = this.toggleHistory.bind(this);
        this.cancelInvoice = this.cancelInvoice.bind(this);

    }

    componentDidMount() {

        this.props.getSubscriptions();
        this.props.getInvoices();

        if (!this.props.charging) {
            this.props.getSettings();
        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (JSON.stringify(prevProps.subscriptions) !== JSON.stringify(this.props.subscriptions)) {

            const displayRows = this.props.subscriptions.map((sub, index) => false);
            this.setState({displayRows});

        }


    }

    toggleHistory(subIndex) {


        const displayRows = [...this.state.displayRows];
        displayRows[subIndex] = !displayRows[subIndex];

        this.setState({displayRows});

    }

    async deleteSub(subIndex) {

        if (!confirm('Cancel subscription? Your subscription will remain active until the end of the billing period at which point you will no longer be charged.'))
            return;

        const subscription = this.props.subscriptions[subIndex];

        try{

            const result = await this.props.deleteSubscription(subscription._id);

            // Update subscription list
            this.props.getSubscriptions();

        }catch (err) {
            // Error should be handled by thunk
            console.error(err.message);
        }


    }

    async cancelInvoice(invoiceid){

        if(!confirm('Cancel invoice?'))
            return;

        try{

            axios.defaults.headers.common['x-access-token'] = localStorage.getItem('melon-token');

            const {data} = await axios.delete(config.server_url + '/api/v1/invoices/' + invoiceid);

            if(!data.success)
                throw new Error(data.msg);

            this.props.getInvoices();

        }catch (err) {

            console.error(err.message);
            this.setState({invoiceError: err.message});

            setTimeout(
                ()=>{
                    this.setState({invoiceError: ''});
                }, 3000
            )

        }

    }

    render() {

        return (

            <div className='page-container'>

                {
                    (this.props.invoices && !!this.props.invoices.length) &&

                    <div key='invoice-list' className='list-invoices col-md-8 col-md-offset-2'>

                        <h3>You have outstanding invoices</h3>

                        <div>

                            <p>
                                You will have open invoices if:
                            </p>

                            <ul>
                                <li>
                                    Your subscription was renewed but your payment failed
                                </li>
                                <li>
                                    Your subscription was renewed but your payment requires further action
                                </li>
                                <li>
                                    You began the payment process for a membership but did not complete it
                                </li>
                            </ul>

                            <p>
                                You can ignore or cancel outstanding invoices although your subscription may expire and
                                be cancelled as a
                                result.
                            </p>

                        </div>

                        <table className='table invoices-table'>
                            <thead>
                            <tr>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Amount</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                this.props.invoices.map(
                                    (invoice, index) => (
                                        <tr key={index}>
                                            <td>{invoice.description}</td>
                                            <td>{invoice.status}</td>
                                            <td>{invoice.amount}</td>
                                            <td>
                                                <button className='btn btn-sm btn-primary' onClick={() => {
                                                    this.setState({invoiceToPay: invoice})
                                                }}>Pay invoice
                                                </button>
                                                <button className='btn btn-sm btn-danger' onClick={() => {
                                                    this.cancelInvoice(invoice.id)
                                                }}>Cancel invoice
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                )
                            }

                            </tbody>
                        </table>


                        <PayInvoice invoice={this.state.invoiceToPay}/>


                    </div>
                }

                <div key='subscription-list' className='list-subscriptions col-md-8 col-md-offset-2'>

                    <h3>Your Subscriptions</h3>

                    {
                        !this.props.subscriptions.length &&
                        <div className='get-subscription'>

                            <p style={{marginBottom: 20}}>
                                You have no active subscriptions. Get unlimited notes and flashcards with a Studymelon
                                subscription
                                starting at
                                only {this.props.charging && this.props.charging.plans && this.props.charging.plans.yearly.monthly_display_cost} per
                                month.
                            </p>

                            <Link style={{textDecoration: 'none'}} to='/subscribe'>
                                <p className='alert alert-info'>Click here to subscribe</p>
                            </Link>

                        </div>
                    }

                    {
                        !!this.props.subscriptions.length &&
                        <div className='subs-list table-responsive'>
                            <table className='table subs-table'>
                                <thead>
                                <tr>

                                    <th>
                                        Subscription type
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Expires
                                    </th>
                                    <th>
                                        History
                                    </th>
                                    <th></th>
                                </tr>
                                </thead>

                                <tbody>

                                {
                                    this.props.subscriptions.map(
                                        (subscription, subIndex) => (

                                            [
                                                <tr key={subIndex}>

                                                    <td>
                                                        renews every&nbsp;
                                                        {
                                                            subscription.interval_count > 1 ? `${subscription.interval_count} ${subscription.interval}s` : subscription.interval
                                                        }
                                                    </td>
                                                    <td>
                                                        {subscription.status === 'ACTIVE' && <span>active</span>}
                                                        {subscription.status === 'CANCELLED' && <span>cancelled</span>}
                                                        {subscription.status === 'PENDING_CANCEL' &&
                                                        <span>pending cancel</span>}
                                                        {subscription.status === 'incomplete' &&
                                                        <span>incomplete</span>}
                                                    </td>
                                                    <td>
                                                        {!subscription.expires && <span>n/a</span>}
                                                        {subscription.expires && moment(subscription.expires).format('LLL')}
                                                    </td>
                                                    <td>

                                                        <button className='btn btn-link' onClick={() => {
                                                            this.toggleHistory(subIndex)
                                                        }}>
                                                            view
                                                        </button>

                                                    </td>
                                                    <td>
                                                        {

                                                            subscription.status === 'ACTIVE' &&

                                                            <button className='btn btn-sm btn-danger' onClick={() => {
                                                                this.deleteSub(subIndex)
                                                            }}>
                                                                cancel
                                                            </button>

                                                        }
                                                    </td>
                                                </tr>,

                                                <tr key={subIndex + '-history'}>

                                                    <td colSpan="4" className='sub-history-container'>

                                                        <table className="sub-history-table">
                                                            <tbody>
                                                            {
                                                                subscription.history.map(
                                                                    (item, index) => (
                                                                        <tr key={index}>
                                                                            <td className=''>
                                                                                <div
                                                                                    className={this.state.displayRows[subIndex] ? 'sub-history-div show-history' : 'sub-history-div'}>{moment(item.createdAt).format('ll')}</div>
                                                                            </td>
                                                                            <td>
                                                                                <div
                                                                                    className={this.state.displayRows[subIndex] ? 'sub-history-div show-history' : 'sub-history-div'}>{item.event}</div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>

                                            ]
                                        )
                                    )
                                }

                                </tbody>
                            </table>



                        </div>
                    }

                </div>


            </div>


        )


    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        subscriptions: state.subscriptions,
        charging: state.settings.charging,
        invoices: state.invoices
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSubscriptions: () => dispatch(getSubscriptionsRequest()),
        getSettings: () => dispatch(getSettingsRequest()),
        deleteSubscription: subscriptionId => dispatch(deleteSubscriptionRequest(subscriptionId)),
        getInvoices: () => {
            dispatch(getInvoicesRequest())
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListSubscriptions));
