
import * as throbberActions from './throbberActions';
import axios from 'axios';

import config from '../../../config';

const ADD_TAGS = 'ADD_TAGS',
    GET_TAGS = 'GET_TAGS',
    ADD_TAG = 'ADD_TAG',
    EDIT_TAG = 'EDIT_TAG',  // Set tag as editable in the UI
    UPDATE_TAG = 'UPDATE_TAG',
    REMOVE_TAG = 'REMOVE_TAG',
    PROCESSING = 'PROCESSING',
    PROCESSING_ERROR = 'PROCESSING_ERROR';

export {ADD_TAGS, GET_TAGS, ADD_TAG, EDIT_TAG, UPDATE_TAG, REMOVE_TAG, PROCESSING, PROCESSING_ERROR};

// Thunks - api requests related to tags
export function getTagsRequest(){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(processing(true));

        return axios.get(config.server_url + '/api/v1/tag').then(
            (reply)=>{

                dispatch(processing(false));

                const response = reply.data;

                if(!response.success)
                    return dispatch(processingError(response.msg));

                dispatch(addTags(response.tags));

            }
        ).catch(
            (err)=>{

                console.error('ALARRRRMMM!! ' + err.message);
                dispatch(processingError(err.message));

            }
        );

    }

}

export function addTagRequest(tag){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(processing(true))

        return axios.post(config.server_url + '/api/v1/tag', {tag}).then(
            (reply)=>{

                dispatch(processing(false));

                const response = reply.data;

                if(!response.success)
                    return dispatch(processingError(response.msg));

                dispatch(addTag(response.tag));

            }
        ).catch(
            (err)=>{

                console.error('ALARRRRMMM!! ' + err.message);

                dispatch(processingError(err.message));

            }
        );

    }

}

export function updateTagRequest(tagID, updatedTag){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        delete updatedTag._id;

        dispatch(processing(true));

        return axios.put(config.server_url + '/api/v1/tag/' + tagID, {updates: updatedTag}).then(
            (reply)=>{

                dispatch(processing(false));

                const response = reply.data;

                if(!response.success)
                    return dispatch(processingError(response.msg));

                dispatch(updateTag(tagID, response.tag));

            }
        ).catch(
            (err)=>{

                console.error('ALARRRRMMM!! ' + err.message);
                dispatch(processingError(err.message));

            }
        );

    }

}

export function deleteTagRequest(tagID){

    return (dispatch, getState)=>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(processing(true))

        return axios.delete(config.server_url + '/api/v1/tag/' + tagID).then(
            (reply)=>{

                dispatch(processing(false));

                const response = reply.data;

                if(!response.success)
                    return dispatch(processingError(response.msg));

                dispatch(removeTag(tagID));

            }
        ).catch(
            (err)=>{

                console.error('ALARRRRMMM!! ' + err.message);
                dispatch(processingError(err.message));

            }
        );

    }

}


// Redux actions
export function addTags(tags) {

    // Overwrite any existing tags
    return {
        type : ADD_TAGS,
        tags
    }

}

export function addTag(tag){

    return {
        type : ADD_TAG,
        tag
    }

}

export function updateTag(tagID, tagUpdates){

    return {
        type : UPDATE_TAG,
        tagID,
        tagUpdates
    }

}

export function removeTag(tagID){

    return{
        type : REMOVE_TAG,
        tagID
    }

}

export function processing(isProcessing){
    return{
        type: PROCESSING,
        isProcessing
    }
}

export function processingError(error){
    return{
        type: PROCESSING_ERROR,
        error
    }
}


