import React from 'react';
import {connect} from 'react-redux';
import {v4 as uuid} from 'uuid';

import * as categoryActions from '../../../state/actions/categoryActions';
import * as tagActions from '../../../state/actions/tagActions'

import './category-tag-manager.less';

class CategoryTagManager extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            newTag: '',
            tags: []
        };

        this.uuid = uuid();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getTags = this.getTags.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(JSON.stringify(prevProps.tags) !== JSON.stringify(this.props.tags)){
            this.getTags()
        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });

    }

    getTags(){

        const category = this.props.category,
            tags = this.props.tags.map(
                taggy => {
                    const active = !!(category.tags && category.tags.indexOf(taggy._id) > -1);
                    return {...taggy, active};
                }
            );

        this.setState({tags});

    }

    showModal(ev) {

        ev.preventDefault();

        this.getTags()

        this.setState({showModal: true},
            () => {

                const tagModal = $(`#${this.uuid}`);
                tagModal.modal();

                tagModal.on('hidden.bs.modal', (e) => {
                    this.setState({showModal: false});
                });

            }
        );

    }

    updateCategory(ev, tagID){

        const categoryTags = [],
            tags = this.state.tags.map(
            taggy => {

                if(taggy._id === tagID){
                    taggy.active = !taggy.active
                }

                if(taggy.active){
                    categoryTags.push(taggy._id)
                }

                return taggy;
            }
        );


        this.props.updateCategory(this.props.category._id, {tags: categoryTags}).then(
            ()=>{
                this.setState({tags});
            }
        )

    }

    addTag(ev) {

        ev.preventDefault();

        this.props.addTag({name: this.state.newTag}).then(
            () => {
                this.setState({newTag: ''});
                this.props.getTags()
            }
        )

    }

    render() {

        return (

            <div className="btn-group category-tag-manager">

                <button
                    type="button"
                    className='manage-tag-button'
                    onClick={ev => {
                        this.showModal(ev)
                    }}
                >
                    <span className="icon-category-tag">#</span>
                </button>

                {

                    <div id={this.uuid} className="modal fade" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">

                                    <h4 className="modal-title">Manage tags for {this.props.category.name}</h4>

                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>

                                </div>
                                <div className="modal-body">

                                    <div className='tags-list-container'>

                                        {
                                            this.state.tags.length &&
                                            <div className='tags-list'>
                                                {
                                                    this.state.tags.map((tag, i) => (
                                                            <div className='tag-item' key={i}>
                                                                <label>
                                                                    <input type='checkbox' checked={tag.active} onChange={ev => { this.updateCategory(ev, tag._id) }} />&nbsp;{tag.name}
                                                                </label>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        }

                                        {
                                            !this.props.tags.length &&
                                                <p className='alert alert-info'>You do not have any tags</p>
                                        }

                                    </div>

                                </div>

                                <div className="modal-footer">

                                    <form className='tag-form-inline' onSubmit={ev => {this.addTag(ev)}}>

                                        <input type='text' id='newTag' value={this.state.newTag}
                                               placeholder='Start typing...'
                                               className='form-control form-control-sm'
                                               onChange={this.handleInputChange}/>

                                        <button className='btn btn-primary btn-sm'
                                                disabled={this.state.newTag.length < 3}
                                                type='submit'>Add tag</button>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>

                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                }

            </div>

        )

    }

}

const mapStateToProps = (state, ownState) => {
    return {
        tags: state.tags.tags
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateCategory: (categoryID, update) => dispatch(categoryActions.editCategoryRequest(categoryID, update)),
        addTag: newTag => dispatch(tagActions.addTagRequest(newTag)),
        getTags: ()=> dispatch(tagActions.getTagsRequest())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTagManager);
