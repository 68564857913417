
const environments = {

    dev: {
        env: 'dev',
        base_url: 'http://localhost:8080',
        server_url : 'http://localhost:3000',
        port : 3000,
        stripe_public_key: 'pk_test_vPRAK2wOmNya7BZxStuLkbae00FPkXK9gH',
        google_client_id: '909479105207-r0f4qp05p7p2ocsccec0o8gfnchu3v5k.apps.googleusercontent.com',
        facebook_app_id: '919466155231825',
        twitter: {
            request_token_uri: 'http://localhost:3000/api/v1/webhooks/twitter/request-token',
            authenticate_uri: 'http://localhost:3000/api/v1/webhooks/twitter/authenticate'
        },
        github: {
            authenticate_uri: 'https://github.com/login/oauth/authorize',
            client_id: 'ed68a052a88536902c97'
        }
    },
    test: {
        env: 'test',
        base_url: 'http://localhost:3000',
        server_url : 'http://localhost:3000',
        port : 3000,
        google_client_id: '909479105207-r0f4qp05p7p2ocsccec0o8gfnchu3v5k.apps.googleusercontent.com',
        facebook_app_id: '919466155231825',
        twitter: {
            request_token_uri: 'http://localhost:3000/api/v1/webhooks/twitter/request-token',
            authenticate_uri: 'http://localhost:3000/api/v1/webhooks/twitter/authenticate'
        },
        github: {
            authenticate_uri: 'https://github.com/login/oauth/authorize',
            client_id: 'ed68a052a88536902c97'
        }
    },
    production: {
        env: 'production',
        base_url: 'https://app.studymelon.com',
        server_url: 'https://api.studymelon.com',
        google_client_id: '909479105207-r0f4qp05p7p2ocsccec0o8gfnchu3v5k.apps.googleusercontent.com',
        facebook_app_id: '919466155231825',
        twitter: {
            request_token_uri: 'http://localhost:3000/api/v1/webhooks/twitter/request-token',
            authenticate_uri: 'http://localhost:3000/api/v1/webhooks/twitter/authenticate'
        },
        github: {
            authenticate_uri: 'https://github.com/login/oauth/authorize',
            client_id: 'ed68a052a88536902c97'
        }
    }
};

const conf = process.env.NODE_ENV ? environments[process.env.NODE_ENV] : environments['dev'];
module.exports = conf;
