
// Actions
const SHOW_THROBBER = 'SHOW_THROBBER',
    HIDE_THROBBER = 'HIDE_THROBBER',
    SHOW_MESSAGE = 'SHOW_MESSAGE',
    SHOW_ERROR = 'SHOW_ERROR';

export { SHOW_THROBBER, HIDE_THROBBER, SHOW_MESSAGE, SHOW_ERROR };

export const throbberTypes = {
    ERROR : 'ERROR',
    INFO : 'INFO'
};

// Action creators
export function showThrobber(message = 'please wait...', showLoading = true){
    return {
        type : SHOW_THROBBER,
        showThrobber : true,
        throbberType : throbberTypes.INFO,
        showLoading,
        message
    }
}

export function hideThrobber(){
    return {
        type : HIDE_THROBBER,
        showThrobber : false,
        showLoading : false,
        message : ''
    }
}

export function showMessage(message = '') {
    return {
        type : SHOW_MESSAGE,
        showThrobber : true,
        throbberType: throbberTypes.INFO,
        showLoading : false,
        message
    }
}

export function showError(message) {
    return {
        type : SHOW_ERROR,
        showThrobber : true,
        throbberType : throbberTypes.ERROR,
        showLoading : false,
        message
    }
}

