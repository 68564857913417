import React from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

import * as throbberActions from '../../../state/actions/throbberActions';
import * as userActions from '../../../state/actions/userActions';

import SocialLoginContainer from '../../../components/socialLogin/SocialLoginContainer';

import axios from 'axios/index';
import config from '../../../../config.js';

import './register.less';

class Register extends React.Component {

    constructor(props) {

        super(props);

        // Don't ask. Convoluted way of managing general idiocy from Twitter.
        if (props.match.params.token) {

            // Will only get here when the mini-browser is referred here by the server
            try {

                const token = Buffer.from(props.match.params.token, 'base64').toString('ascii');

                // Store JWT token
                localStorage.setItem('melon-token', token);
                return window.self.close();

            } catch (err) {

                alert(`Ok this *super* embarrassing: things aren't working due to Twitter API idiocy. Reload? Contact Support? PANIIIIIIIC!!!`);
                window.self.close();
            }

        }

        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            registrationSuccessful: false,
        };

        this.register = this.register.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    register(ev) {

        ev.preventDefault();
        this.props.showThrobber();

        const newUser = this.state;

        axios.post(config.server_url + '/auth/register', {userinfo: newUser}).then(
            (reply) => {

                this.props.hideThrobber();

                const response = reply.data;

                if (!response.success) {

                    this.props.showError(response.msg);
                    return;
                }

                // Display success message
                // Dispatch USER_REGISTERED event
                //      - Store user object
                // Redirect to dashboard

                this.setState({registrationSuccessful: true});
                this.props.userRegistered(response.user);

                // Store access token
                localStorage.setItem('melon-token', response.token);

                setTimeout(
                    () => {
                        this.props.history.push('/');
                    }, 2000
                );

            }
        ).catch(
            (err) => {

                console.error(err.message);
                this.props.showError(err.message);
            }
        )

    }

    render() {

        return (

            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 register-page">

                <h1>Register for Studymelon</h1>

                <SocialLoginContainer />

                <form onSubmit={this.register}>

                    <div className="form-group">

                        <label>First name</label>

                        <input
                            type="text"
                            required
                            className="form-control"
                            name="firstname"
                            onChange={this.handleInputChange}
                            value={this.state.firstname}/>

                    </div>

                    <div className="form-group">

                        <label>Last name</label>

                        <input
                            type="text"
                            required
                            className="form-control"
                            name="lastname"
                            onChange={this.handleInputChange}
                            value={this.state.lastname}/>

                    </div>

                    <div className="form-group">

                        <label>Email</label>

                        <input
                            type="email"
                            required
                            className="form-control"
                            name="email"
                            onChange={this.handleInputChange}
                            value={this.state.email}/>

                    </div>

                    <div className="form-group">

                        <label>Password</label>

                        <input
                            type="password"
                            required
                            className="form-control"
                            name="password"
                            onChange={this.handleInputChange}
                            value={this.state.password}/>

                    </div>

                    {this.state.registrationSuccessful &&

                    <p className="alert alert-success">
                        Registration successful. You will now be redirected...
                    </p>

                    }

                    <div className="form-group">

                        <button type="submit" className="btn btn-primary pull-right">submit</button>

                        <div className="clearfix"></div>

                    </div>

                    <div className="form-group">

                        <Link className="alert alert-success cursor-pointer btn-full-width" to='/login'>
                            Click here if you are already registered
                        </Link>

                    </div>

                </form>

            </div>

        )

    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        throbber: state.throbber
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        showThrobber: () => dispatch(throbberActions.showThrobber()),
        showError: (msg) => dispatch(throbberActions.showError(msg)),
        hideThrobber: () => dispatch(throbberActions.hideThrobber()),
        userRegistered: (user) => dispatch(userActions.userRegistered(user))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register))
