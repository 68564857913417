import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux';

import poweredByStripe from '../../../../public/img/powered-by-stripe.png';

import config from '../../../../config';
import './stripe-card.less';
import {getInvoicesRequest} from "../../../state/actions/invoiceActions";

class StripeUpdateCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            processing: false,
            activationSuccessMessage: false,
            stripeError: ''
        };

        this.submitCard = this.submitCard.bind(this);
        this.payInvoice = this.payInvoice.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.invoice)
            console.log(this.props.invoice);

    }

    componentDidMount() {

        this.stripe = window.Stripe(config.stripe_public_key);

        const elements = this.stripe.elements();

        this.stripeCard = elements.create('card');
        this.stripeCard.mount('#update-card-element');

        this.stripeCard.addEventListener('change', function (event) {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

    }

    submitCard(ev) {

        ev.preventDefault();

        this.state.processing = true;
        this.setState({processing: true});

        // Some payment methods and cards may require further authentication. In all cases
        // retrieve a payment intent from the server. If the transaction requires no further authentication
        // send back the payment intent ID to confirm the transaction.
        this.stripe.createToken(this.stripeCard).then(
            (result) => {

                if (result.error) {
                    this.setState({processing: false});
                }

                if (this.props.invoice && this.props.invoice.id) {
                    // There is an invoice that is unpaid and the payment method has been updated
                    return this.payInvoice(result.token, this.props.invoice.id)
                }

                // updateCard(result.token);

            });


    };

    payInvoice(stripetoken, invoiceid) {

        this.setState({processing: true});

        axios.defaults.headers.common['x-access-token'] = localStorage.getItem('melon-token');

        axios.put(config.server_url + `/api/v1/invoices/pay/${invoiceid}`, {stripetoken}).then(
            ({data}) => {

                this.setState({processing: false});

                if (!data.success) {

                    this.setState({stripeError: `Sorry but an error occurred: ${data.msg}`});

                    setTimeout(
                        () => {
                            this.setState({stripeError: ''})
                        }, 3000
                    );

                    return;

                }

                if (data.success && !data.requires_action) { // Success

                    this.setState({activationSuccessMessage: true});
                    this.props.getInvoices();

                    setTimeout(
                        () => {
                            this.setState({activationSuccessMessage: false});
                            $('.update-card-modal').modal('hide');
                            $('body').removeClass('modal-open');
                            $('.modal-backdrop').remove();
                        }, 3000
                    );

                    return;
                }

                // Do SCA. This is completed client side.
                if (data.requires_action) {
                    return this.handleSCA(data.payment_intent_client_secret)
                }

                // Error
                this.setState({stripeError: `Sorry but an error occurred - unhandled status: ${data.status}`});

                setTimeout(
                    () => {
                        this.setState({stripeError: ''})
                    }, 3000
                );


            }
        ).catch(
            error => {

                this.setState(
                    {
                        stripeError: `Sorry but an error occurred - unhandled status: ${error.message}`,
                        processing: false
                    }
                );

                setTimeout(
                    () => {
                        this.setState({stripeError: ''})
                    }, 3000
                );


            }
        )

    };

    handleSCA(clientSecret) {

        this.setState({processing: true});

        this.stripe.handleCardPayment(clientSecret, this.stripeCard).then(response => {

            this.setState({processing: false});

            if (response.error) {

                this.setState({stripeError: `Sorry but an error occurred: ${response.error.message}`});

                return setTimeout(
                    () => {
                        this.setState({stripeError: ''});
                    }, 3000
                )

            }

            // Unless there has been a problem, the invoice will now have been paid.
            // Also a webhook will have been generated that will have been sent to the backend to activate the membership
            this.setState({activationSuccessMessage: true});

            setTimeout(
                () => {
                    this.setState({activationSuccessMessage: false});
                    $('.update-card-modal').modal('hide');
                    $('body').removeClass('modal-open');
                    $('.modal-backdrop').remove();
                }, 3000
            );


        }).catch(
            err => {

                this.setState({processing: false});
                this.state.stripeError = 'Sorry but an error occurred:' + err.msg;

                setTimeout(
                    () => {
                        this.state.stripeError = '';
                    }, 3000
                )

            }
        );

    };

    updateCard(stripetoken) {

        this.state.processing = true;

        $http.put(API_URL + `/api/v1/invoices/update-card`, {stripetoken}).then(
            reply => {

                $rootScope.throbber.hide();
                this.state.processing = false;

                const response = reply.data;

                if (!response.success) {

                    this.state.stripeError = `Sorry but an error occurred: ${response.msg}`;

                    $rootScope.safeApply();

                    $timeout(
                        () => {
                            this.state.stripeError = '';
                        }, 3000
                    );

                    return;

                }

                if (response.success && !response.requires_action) {

                    this.state.cardUpdatedSuccessMessage = true;

                    $timeout(
                        () => {
                            this.state.cardUpdatedSuccessMessage = false;
                            $('.update-card-modal').modal('hide');
                            $('body').removeClass('modal-open');
                            $('.modal-backdrop').remove();
                            this.state.activationSuccess();
                        }, 3000
                    );

                    return;
                }

                // Do SCA. This is completed client side.
                if (response.requires_action) {
                    return handleSCA(response.payment_intent_client_secret)
                }

                this.state.stripeError = `Sorry but an error occurred - unhandled status: ${response.status}`;

                $timeout(
                    () => {
                        this.state.stripeError = '';
                    }, 3000
                )

            }
        ).catch(
            error => {

                console.error(error.message);

                this.state.stripeError = `Sorry but an error occurred - unhandled status: ${error.message}`;
                this.state.processing = false;

                $timeout(
                    () => {
                        this.state.stripeError = '';
                    }, 3000
                );

            }
        )

    };

    cancelPayment() {

        $('.update-card-modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();

    };

    render() {

        return (
            <div className='stripe-update-card'>

                <div className='stripe-card-label'>Please enter your card details below</div>

                <form onSubmit={ev => {
                    this.submitCard(ev)
                }}>

                    <div id='update-card-element'></div>

                    <div id='card-errors'></div>

                    {
                        this.state.processing &&
                        <p className="processing-message alert alert-info"
                           style={{
                               padding: '3px 0',
                               textAlign: 'center',
                               marginBottom: 5,
                               background: 'black',
                               fontSize: '.8em'
                           }}>
                            Please be patient. Security checks on your card may take a few moments.
                        </p>
                    }

                    <div className='card-logo-container'>

                        <a href='https://stripe.com' target='_blank'>
                            <img className='update-card-stripe-logo' src={poweredByStripe}/>
                        </a>

                        <div className='submit-buttons'>

                            <button className='btn btn-default stripe-cancel-button'
                                    onClick={this.cancelPayment}>cancel
                            </button>
                            <button type='submit' disabled={this.state.processing} className='btn btn-primary'>submit
                            </button>

                        </div>

                    </div>

                    {this.state.stripeError &&
                    <p className='alert alert-danger stripe-card-message'>{this.state.stripeError}</p>}

                    {
                        this.state.activationSuccessMessage && <p className='alert alert-success stripe-card-message'>
                            Your payment has succeeded - thanks very much for your payment. You will now be redirected.
                        </p>
                    }

                </form>


            </div>
        )

    }

}

const mapStateToProps = state => {
    return {
        invoices: state.invoices
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getInvoices: () => {
            dispatch(getInvoicesRequest())
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(StripeUpdateCard);
