
// These actions are *only* concerned with user actions (not network / authentication ones)
// Network / authentication issues are handled by the throbber

// Actions
const SET_USER = 'SET_USER',
    USER_LOGGED_IN = 'USER_LOGIN',
    USER_LOGGED_OUT = 'USER_LOGGED_OUT',
    USER_REGISTERED = 'USER_REGISTER';

export { SET_USER, USER_LOGGED_IN, USER_LOGGED_OUT, USER_REGISTERED };

export function setUser(user){
    return{
        type : SET_USER,
        user
    }
}

// Action creators
export function userLoggedIn(user){

    return{
        type : USER_LOGGED_IN,
        user
    }

}

export function userLoggedOut(){
    return { type : USER_LOGGED_OUT, user : null }
}

export function userRegistered(user) {
    return{
        type : USER_REGISTERED,
        user
    }
}