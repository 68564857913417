import React from 'react';

import NoteEditor from '../NoteEditor';
import JavascriptAceNote from './languages/JavascriptAceNote.jsx';
import PHPAceNote from './languages/PHPAceNote.jsx';
import CSSAceNote from './languages/CSSAceNote.jsx';
import CPPAceNote from './languages/CPPAceNote.jsx';
import JSONAceNote from './languages/JSONAceNote.jsx';
import HTMLAceNote from './languages/HTMLAceNote.jsx';
import PythonAceNote from './languages/PythonAceNote.jsx';
import JavaAceNote from './languages/JavaAceNote.jsx';


const LanguageChooser = (props) => {


    const note = props.note;

    if (note.type === 'ace' && note.acelanguage === 'javascript') {

        return <JavascriptAceNote worksheetLocked={props.worksheetLocked}
                                  note={note}/>

    }

    if (note.type === 'ace' && note.acelanguage === 'php') {

        return <PHPAceNote worksheetLocked={props.worksheetLocked}
                           note={note}/>

    }

    if (note.type === 'ace' && note.acelanguage === 'c_cpp') {

        return <CPPAceNote worksheetLocked={props.worksheetLocked}
                           note={note}/>

    }

    if (note.type === 'ace' && note.acelanguage === 'css') {

        return <CSSAceNote worksheetLocked={props.worksheetLocked}
                           note={note}/>

    }

    if (note.type === 'ace' && note.acelanguage === 'json') {

        return <JSONAceNote worksheetLocked={props.worksheetLocked}
                           note={note}/>

    }

    if (note.type === 'ace' && note.acelanguage === 'html') {

        return <HTMLAceNote worksheetLocked={props.worksheetLocked}
                            note={note}/>

    }

    if (note.type === 'ace' && note.acelanguage === 'python') {

        return <PythonAceNote worksheetLocked={props.worksheetLocked}
                            note={note}/>

    }

    if (note.type === 'ace' && note.acelanguage === 'java') {

        return <JavaAceNote worksheetLocked={props.worksheetLocked}
                              note={note}/>

    }

    return <NoteEditor worksheetLocked={props.worksheetLocked}
                       note={note}/>

};

export default LanguageChooser;
