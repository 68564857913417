

import {combineReducers} from 'redux';
import {throbber} from './reducers/throbberReducers';
import {user} from './reducers/userReducers'
import {categories} from './reducers/categoryReducers';
import {worksheet} from './reducers/worksheetReducers';
import {flashcardSet} from './reducers/flashcardSetReducers';
import {flashcardsetList} from "./reducers/flashcardsetListReducers";
import {search, searchQuery} from './reducers/searchReducers';
import {settings} from './reducers/settingsReducers';
import {subscriptions} from './reducers/subscriptionReducers';
import {invoices} from './reducers/invoiceReducers';
import {tags} from './reducers/tagReducers';

const melonApp = combineReducers(
        {
            throbber,
            user,
            categories,
            worksheet,
            flashcardSet,
            flashcardsetList,
            search,
            searchQuery,
            settings,
            subscriptions,
            invoices,
            tags
        }
    );

export default melonApp
