
import AceNote from '../AceNote.jsx';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as worksheetActions from "../../../../state/actions/worksheetActions";


class PHPAceNote extends AceNote{

    constructor(props){

        super(props);

        console.log('Instantiating PHP');

        this.state = { loaded : false };

        import('brace/mode/php').then(
            ()=>{

                console.log('PHP note');
                this.setState(Object.assign({}, this.props, {loaded : true}));

            }
        )

    }



}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        worksheet: state.worksheet
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        removeNote: (noteID) => dispatch(worksheetActions.removeNoteFromWorksheetRequest(noteID)),
        updateNote: (noteID, updatedNote) => dispatch(worksheetActions.updateNoteRequest(noteID, updatedNote)),
        setNoteEditable : (noteID) => dispatch(worksheetActions.setNoteEditable(noteID)),
        unsetNoteEditable : (noteID) => dispatch(worksheetActions.unsetNoteEditable())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PHPAceNote));
