import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';

import * as searchActions from '../../../state/actions/searchActions';


class SearchResults extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      results: null
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (JSON.stringify(prevProps.search) !== JSON.stringify(this.props.search)) {

      this.setState({results: this.props.search.results});

    }

  }

  // Clear search query and search results, follow link
  followLink(newPath) {

    this.props.deleteSearchResults();
    this.props.history.push(newPath);

  }

  render() {

    const results = this.state.results;

    if (!results)
      return null;

    return (

      <div className="search-results">

        <div className="search-results-list">

          {
            !Object.keys(results.worksheets).length &&

            <span>there are no results for this search</span>
          }

          {
            !!Object.keys(results.worksheets).length &&

            <div className="results-section">

              {
                Object.keys(results.worksheets).map(
                  (worksheetID, i) =>

                    <div key={i} className="results-items">

                      <h5 className="results-name"
                          onClick={() => {
                            this.followLink('/worksheet/' + worksheetID)
                          }}
                          dangerouslySetInnerHTML={{__html: results.worksheets[worksheetID].name}}
                      />

                      <ul className="results-list">

                        {
                          results.worksheets[worksheetID].notes.map(
                            (note) =>

                              note.matchStrings.map(
                                (match, i) =>

                                  <li key={i}
                                      className="results-list-item"
                                      onClick={() => {
                                        this.followLink('/worksheet/' + worksheetID)
                                      }}
                                      dangerouslySetInnerHTML={{__html: `<span className="result-item-keyword">${match.keyword}</span> ${match.nextString}`}}
                                  />
                              )
                          )

                        }

                      </ul>

                    </div>
                )
              }


            </div>

          }


        </div>

      </div>

    )

  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    search: state.search,
    searchQuery: state.searchQuery
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSearchResults: () => {
      dispatch(searchActions.deleteSearchResults());
      dispatch(searchActions.updateSearchQuery(''))
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResults))
