

import Immutable from 'seamless-immutable';

import {UPDATE_SEARCH_QUERY, UPDATE_SEARCH_RESULTS, DELETE_SEARCH_RESULTS} from '../actions/searchActions';

const init = {
    results : null
};

function search(state = init, action){

    const immState = Immutable(state);

    switch(action.type){

        case UPDATE_SEARCH_RESULTS :

            return {
                results: action.results
            };

        case DELETE_SEARCH_RESULTS :

            return {
                results : null
            }

    }

    return immState

}


function searchQuery(state = '', action){

    const immState = Immutable(state);

    switch (action.type){

        case UPDATE_SEARCH_QUERY :

            return action.query

    }

    return immState;

}

export {search, searchQuery};