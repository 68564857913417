
// ./src/components/App.js
import React  from 'react';

import {Route, Switch, withRouter} from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Throbber from './components/Throbber';

import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import NotFound from "./components/NotFound";
import CategoryList from "./components/category/CategoryList";
import WorksheetDisplay from "./components/worksheet/WorksheetDisplay";
import FlashcardSetDisplay from "./components/flashcards/FlashcardSetDisplay";
import SubscribePage from './pages/SubscribePage';
import ListSubscriptions from './pages/Account/ListSubscriptions';
import ListInvoices from './pages/Account/ListInvoices';


import { connect } from 'react-redux';
import * as userActions from './state/actions/userActions';
import * as auth from './lib/userAuth';


class App extends React.Component {

    constructor(props) {

        super(props);

        const path = props.location.pathname;

        // Page access requires user token
        let userInfo = null;

        this.state = {
            pageReady : false
        };

        try{

            userInfo = auth.getUserInfo();

            // Redirect if the user is not authenticated
            if(!userInfo && path !== '/login' &&
                !/register/g.test(path) &&
                path !== '/forgot-password' &&
                !/reset-password/g.test(path)){

                props.history.push('/login');
                return;
            }

        }catch(err){

            console.error(err.message);
            // props.showError(`Authorisation error: ${err.message}, redirecting...`);
        }

        this.melonVersion = process.env.VERSION;
        this.props.setUser(userInfo);

    }

    componentDidMount(){
        this.setState({pageReady : true });
    }

    render(){

        if(!this.state.pageReady){
            return null;
        }

        return(

            <div className="content">

                <Header {...this.props}/>

                <div className="content-main">

                    <Switch>

                        <Route path="/" exact component={CategoryList} />
                        <Route path="/register/:token?" component={Register} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path='/forgot-password' component={ForgotPassword}/>
                        <Route exact path='/reset-password/:resetkey' component={ResetPassword}/>
                        <Route exact key={this.props.location.pathname} path="/worksheet/:worksheetid" component={WorksheetDisplay} />
                        <Route exact path="/flashcardset/:flashcardsetid" component={FlashcardSetDisplay} />
                        <Route exact path='/subscribe' component={SubscribePage} />
                        <Route exact path='/account/subscriptions' component={ListSubscriptions} />
                        <Route component={NotFound} />

                    </Switch>

                    <div className="clearfix"></div>

                </div> { /* End content-main */ }

                <Throbber/>

                <Footer version={this.melonVersion}/>

            </div>

        )

    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        setUser : (user)=> dispatch(userActions.setUser(user)),
        userLoggedOut : ()=> dispatch(userActions.userLoggedOut())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
