
// Get state
import {createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import logger from 'redux-logger'

import melonApp from './reducers';

const melonStore = createStore(
        melonApp,
        composeWithDevTools(applyMiddleware(thunkMiddleware))
    );

export default melonStore;
