
import React from 'react';

import StripeCreateMembership from '../../../components/stripe/StripeCreateMembership';
import './buy-subscription.less';

const BuySubscription = props =>{

    return(
        <div className='buy-subscription'>

            <h4>
                Purchase {props.chosenPlan.billing_period} subscription
            </h4>

            <div className="subscription-pricing-info">

                <p className="charge-info">
                    You will be charged {props.chosenPlan.billing_period == 'monthly' ? props.chosenPlan.monthly_display_cost : props.chosenPlan.yearly_display_cost }
                    &nbsp;every {props.chosenPlan.billing_period == 'monthly' ? 'month' : 'year'}.
                </p>

            </div>

            <StripeCreateMembership stripeKey={props.stripeKey} plan={props.chosenPlan} />

            <div className="clearfix"></div>


        </div>
    )
    
};
    
    
export default BuySubscription;

