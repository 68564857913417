
import React from 'react';
import Masonry from 'masonry-layout';

export default class SmoothMasonry extends React.Component{

    constructor(props, context) {
        super(props, context);

        this.masonry = null;
    }

    componentDidMount() {

        this.masonry = new Masonry('.masonry-container');

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.masonry = new Masonry('.masonry-container'); // Can't find a way of just updating the existing masonry instance.
    }

    render(){
        return this.props.children
    }

}
