
import Immutable from 'seamless-immutable';

import {
    ADD_WORKSHEET,
    UPDATE_WORKSHEET,
    ADD_NOTE_TO_WORKSHEET,
    REMOVE_NOTE_FROM_WORKSHEET,
    LOCK_WORKSHEET,
    UNLOCK_WORKSHEET,
    SET_NOTE_EDITABLE,
    UNSET_NOTE_EDITABLE,
    UPDATE_NOTE_ON_WORKSHEET,
    DELETE_WORKSHEET
} from '../actions/worksheetActions';

function worksheet(state = {}, action){

    const immState = Immutable(state);

    let newNotes = [];

    switch(action.type){

        case ADD_WORKSHEET :

            return action.worksheet;

        case UPDATE_WORKSHEET :

            return Object.assign({}, immState, action.worksheet);

        case ADD_NOTE_TO_WORKSHEET:

            let notes = immState.notes.concat(action.note);
            return Object.assign({}, immState, {notes : notes});

        case UPDATE_NOTE_ON_WORKSHEET:

            newNotes = immState.notes.map(
                (note)=>{

                    if(note._id === action.noteID){

                        return action.updatedNote

                    }

                    return note;
                }
            );

            return Object.assign({}, immState, { notes: newNotes });

        case REMOVE_NOTE_FROM_WORKSHEET:

            newNotes = immState.notes.filter(note => note._id !== action.noteID);
            return Object.assign({}, immState, {notes: newNotes});

        case SET_NOTE_EDITABLE:

            newNotes = immState.notes.map(note => (Object.assign({}, note, {editing : (note._id === action.noteID)}) ));
            return Object.assign({}, immState, {notes: newNotes});

        case UNSET_NOTE_EDITABLE:

            newNotes = immState.notes.map(note => (Object.assign({}, note, {editing : false}) ));
            return Object.assign({}, immState, {notes: newNotes});


        case DELETE_WORKSHEET:

            return {};

    }

    return immState;

}

export {worksheet}

