
import React from 'react';

import githubLogo from '../../../../public/img/gitgub-logo.png';
import twitterLogo from '../../../../public/img/twitter-logo.png';
import facebookLogo from '../../../../public/img/facebook-logo.png';
import googleLogo from '../../../../public/img/google-logo.png';

import './social-buttons.less';


export function GithubButton(props){
    return(
        <button className='social-login-button github-login' {...props}>
            <img src={githubLogo} /> <span>Login with Github</span>
        </button>
    )
}

export function TwitterButton(props){
    return(
        <button className='social-login-button twitter-login' {...props}>
            <img src={twitterLogo} /> <span>Login with Twitter</span>
        </button>
    )
}

export function FacebookButton(props){
    return(
        <button className='social-login-button facebook-login' {...props}>
            <img src={facebookLogo} /> <span>Login with Facebook</span>
        </button>
    )
}

export function GoogleButton(props){
    return(
        <button className='social-login-button google-login' {...props}>
            <img src={googleLogo} /> <span>Login with Google</span>
        </button>
    )
}
