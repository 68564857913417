import React from 'react';
import {connect} from 'react-redux';

import {getSettingsRequest} from '../../state/actions/settingsActions';

import BuySubscription from "./BuySubscription";

import './subscribe-page.less';

class SubscribePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            plans: null,
            chosenPlan: null
        };

        this.choosePlan.bind(this);

    }

    componentDidMount() {

        // Need settings for sub pricing
        if(!this.props.charging)
            return this.props.getSettingsRequest()

        this.setState({plans: this.props.charging.plans, chosenPlan: this.props.charging.plans.monthly})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps.charging) !== JSON.stringify(this.props.charging)) {
            this.setState({plans: this.props.charging.plans, chosenPlan: this.props.charging.plans.monthly})
        }

    }

    choosePlan(subscribePlan) {

        this.setState({chosenPlan: this.state.plans[subscribePlan]});

    }

    render() {
        return (
            <div className='subscribe-page fade-in col-md-8 col-md-offset-2 col-sm-12'>

                <h2>Subscribe to Studymelon</h2>

                <h4>Create unlimited notes and flashcards</h4>

                <p>
                    Support Studymelon and gain the ability to create unlimited notes and flashcards with a convenient
                    monthly or yearly membership.
                </p>
                <p>
                    You subscription is securely managed by <a target='_blank' href='https://stripe.com'>Stripe</a> and
                    you can cancel without cost anytime.
                    We do not usually give refunds for unused portions of your membership but we will refund your
                    payment if you make your request to &nbsp;
                    <a href='mailto:support@studymelon.com'>support@studymelon.com</a> within 48 hours of making it.
                </p>

                {!this.state.chosenPlan && <p className='alert alert-info'>Please wait...</p> }

                {this.state.chosenPlan && (
                    <div>

                        <div className='plan-container'>

                            <div
                                className={this.state.chosenPlan.billing_period === 'monthly' ? 'plan-block chosen' : 'plan-block'}>

                                <h4>Pay monthly</h4>

                                <div className='choose-plan'>

                                    <div className='plan-amount'>{this.state.plans.monthly.monthly_display_cost} / month</div>

                                </div>

                                <button disabled={this.state.chosenPlan.billing_period === 'monthly'} onClick={() => {
                                    this.choosePlan('monthly')
                                }} className='btn btn-info'>Choose
                                </button>

                            </div>

                            <div
                                className={this.state.chosenPlan.billing_period === 'yearly' ? 'plan-block chosen' : 'plan-block'}>

                                <h4>Pay yearly</h4>

                                <div className='choose-plan'>

                                    <div className='plan-amount'>{ this.state.plans.yearly.monthly_display_cost } / month (pay {this.state.plans.yearly.yearly_display_cost} now)</div>

                                </div>

                                <button disabled={this.state.chosenPlan.billing_period === 'yearly'} onClick={() => {
                                    this.choosePlan('yearly')
                                }} className='btn btn-info'>Choose
                                </button>

                            </div>


                        </div>

                        <BuySubscription stripeKey={this.props.charging.stripe_public_key} chosenPlan={this.state.chosenPlan}/>

                    </div>
                )}

            </div>
        )
    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber,
        charging: state.settings.charging,
        user: state.user
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.showThrobber
        showThrobber: () => dispatch(throbberActions.showThrobber()),
        showError: (msg) => dispatch(throbberActions.showError(msg)),
        hideThrobber: () => dispatch(throbberActions.hideThrobber()),
        getSettingsRequest: () => dispatch(getSettingsRequest())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(SubscribePage);
