import React from 'react';
import {connect} from 'react-redux';
import {getFlashcardsByCategoryRequest} from "../../state/actions/flashcardSetListActions";

import './test-me.less';
import FlashcardTest from "../flashcards/FlashcardTest";

class TestMe extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.testMe = this.testMe.bind(this);

        this.state = {
            testCategories: [],
            doTest: false,
            flashcards: []
        }

    }

    testMe() {
        $('#test-me-modal').modal();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps.categories) !== JSON.stringify(this.props.categories)) {
            this.setState({testCategories: this.props.categories.map(cat => ({...cat, chosen: true}))},
                () => {
                    // console.log(this.state.testCategories);
                }
            )
        }


    }

    toggleCategory(catID) {

        this.setState(
            {
                testCategories: this.state.testCategories.map(
                    category => ({...category, chosen: category._id === catID ? !category.chosen : category.chosen})
                )
            }
        )

    }

    startTest() {

        const categories = this.state.testCategories.filter(cat => cat.chosen).map(cat => cat._id);

        this.props.getFlashcards(categories).then(
            (result) => {

                $('#test-me-modal').modal('hide');

                this.setState(
                    {
                        doTest: false,
                        flashcards: this.props.flashcardsets.reduce(
                            (accum, current) => {

                                return [...accum, ...current.flashcards]

                            }, []
                        )
                    },
                    ()=>{
                        this.setState({doTest: true}); // Necessary to trigger a reset of the 'testing' variable in FlashcardTest
                    }
                )

            }
        )

    }

    render() {

        return (
            <div className='test-me-container'>

                <button className='btn btn-black' onClick={this.testMe}>Test me</button>

                <div className="modal fade test-me-modal" id="test-me-modal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Test Me</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <p className='test-me-blurb'>
                                    Your test will come from all flashcard sets in your currently visible categories
                                    - filter your categories by tag to refine your test.
                                </p>

                                <p>Current test categories:</p>

                                <ul className='test-me-category-list'>
                                    {
                                        this.state.testCategories.map(
                                            (category, i) => <li key={i}>
                                                <label>
                                                    {category.chosen}
                                                    <input type='checkbox' checked={category.chosen}
                                                           onChange={ev => {
                                                               this.toggleCategory(category._id)
                                                           }}/>&nbsp;
                                                    {category.name}
                                                </label>
                                            </li>
                                        )
                                    }
                                </ul>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-black" onClick={ev => {
                                    this.startTest()
                                }}>Let's do this
                                </button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.doTest && <FlashcardTest hideButton={true} testing={this.state.doTest} flashcards={this.state.flashcards}/>}

            </div>
        )


    }

}

const mapStateToProps = state => (
    {
        categories: state.categories,
        flashcardsets: state.flashcardsetList
    }
);

const mapDispatchToProps = dispatch => (
    {
        getFlashcards: (categories) => dispatch(getFlashcardsByCategoryRequest(categories))
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(TestMe)

