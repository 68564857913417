
import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import config from '../../../../config';

import axios from 'axios';

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            newPassword: '',
            confirmNewPassword: '',
            resetError: '',
            resetSuccess: false
        };

        this.resetKey = this.props.match.params.resetkey;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitReset = this.submitReset.bind(this);

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    async submitReset(ev){

        ev.preventDefault();

        try{

            if(this.state.newPassword !== this.state.confirmNewPassword){

                this.setState({resetError: 'passwords don\'t match'});

                setTimeout(
                    ()=>{
                        this.setState({resetError: ''})
                    }, 3000
                );

                return;
            }

            const {data} = await axios.post(`${config.server_url}/auth/reset-password`, {token: this.resetKey, newPassword: this.state.newPassword});

            if(!data.success){
                this.setState({resetError: data.msg});

                setTimeout(
                    ()=>{
                        this.setState({resetError: ''});
                    }, 3000
                );

                return;
            }

            // Success
            this.setState({resetSuccess: true});

            setTimeout(
                ()=>{
                    this.props.history.push('/login');
                }, 3000
            )


        }catch (err) {

            this.setState({resetError: err.message});

            setTimeout(
                ()=>{
                    this.setState({resetError: ''});
                }, 3000
            )

        }

    }

    render() {

        return (
            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2"><h2>Reset Password</h2>

                <form onSubmit={this.submitReset}>

                    <div className="form-group"><label>Please enter your new password (minimum 6 characters)</label>
                        <input type="password"
                               required={true}
                               minLength='6'
                               className="form-control"
                               value={this.state.newPassword}
                               onChange={this.handleInputChange}
                               name="newPassword" />
                    </div>

                    <div className="form-group"><label>Confirm your new password</label>
                        <input type="password"
                               required={true}
                               minLength='6'
                               className="form-control"
                               value={this.state.confirmNewPassword}
                               onChange={this.handleInputChange}
                               name="confirmNewPassword" />
                    </div>

                    {
                        this.state.resetSuccess &&
                        <p className='alert alert-success'>
                            Your password has been reset successfully, now redirecting to login page
                        </p>
                    }

                    {
                        this.state.resetError &&
                         <p className='alert alert-danger'>
                             Sorry but there was an error with this request: {this.state.resetError}
                         </p>
                    }

                    <div className="form-group">
                        <Link to='/login'>back to login page</Link>
                        <button type="submit" className="btn btn-primary pull-right">submit</button>
                        <div className="clearfix"></div>
                    </div>
                </form>
            </div>
        )

    }

}

export default withRouter(ResetPassword)
