import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import './flashcardset-display.less'

import * as flashcardSetActions from '../../../state/actions/flashcardSetActions';

import EditFlashcardsetName from '../EditFlashcardSetName';
import FlashcardSetControls from '../FlashcardSetControls';
import FlashcardDisplay from '../FlashcardDisplay';
import FlashcardTest from '../FlashcardTest';

import * as auth from '../../../lib/userAuth';

class FlashcardSetDisplay extends React.Component {

    constructor(props) {

        super(props);

        this.state = {};

        this.handleInputChange = this.handleInputChange.bind(this);
        this.addFlashcard = this.addFlashcard.bind(this);
        this.deleteFlashcard = this.deleteFlashcard.bind(this);
        this.updateFlashcard = this.updateFlashcard.bind(this);
        this.removeFlashcardSet = this.removeFlashcardSet.bind(this);

    }

    componentDidMount() {

        // Redirect if the user is not authenticated
        if (!auth.getUserInfo()) {

            return this.props.history.push('/login');

        }

        // Retrieve flashcardSet from the api and add it to the props
        this.props.getFlashcardSet(this.props.match.params.flashcardsetid);

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    removeFlashcardSet(ev) {

        ev.preventDefault();
        this.props.deleteFlashcardSet(this.props.flashcardSet._id).then(
            () => {
                this.props.history.push('/');
            }
        )

    }

    addFlashcard(ev) {

        ev.preventDefault();
        this.props.addFlashcardRequest(this.props.match.params.flashcardsetid).then(
            () => {
                this.setState(this.state);
            }
        );

    }

    deleteFlashcard(ev, flashcardID) {

        ev.preventDefault();

        this.props.deleteFlashcardRequest(this.props.match.params.flashcardsetid, flashcardID).then(
            () => {
                this.setState(this.state);
            }
        );

    }

    updateFlashcard(flashcardID, flashcardUpdates) {

        this.props.updateFlashcardRequest(this.props.match.params.flashcardsetid, flashcardID, flashcardUpdates).then(
            () => {
                this.setState(this.state);
            }
        );

    }

    render() {

        if (!this.props.flashcardSet) {
            return (

                <div className="col-md-10 col-md-offset-1 fade-in">

                    <p className='alert alert-info'>Sorry but this flashcard set was not found</p>

                </div>
            )
        }

        if (this.props.flashcardSet.flashcards && this.props.flashcardSet.flashcards.length) {

            return (

                <div className="col-md-10 col-md-offset-1 fade-in">

                    <div className="flashcardset-display">

                        {
                            this.state.editName ?

                                <EditFlashcardsetName finished={() => {
                                    this.setState({editName: false})
                                }} flashcardSet={this.props.flashcardSet}/>

                                :

                                <h4 className={this.props.flashcardSet.locked ? 'flashcardset-header' : 'flashcardset-header name-editable'}>
                                <span className="edit-flashcardset-click"
                                      onClick={() => {
                                          !this.props.flashcardSet.locked && this.setState({editName: true})
                                      }}>{this.props.flashcardSet.name}</span>

                                {
                                    this.props.flashcardSet.flashcards
                                    && this.props.flashcardSet.flashcards.length
                                    && <FlashcardTest flashcards={ this.props.flashcardSet.flashcards } />

                                }

                                </h4>
                        }

                        <div className="flashcard-carousel row">

                            {
                                this.props.flashcardSet.flashcards.map(
                                    (thisFlashcard, i) => {

                                        return <FlashcardDisplay
                                            key={i}
                                            flashcard={thisFlashcard}
                                            locked={this.props.flashcardSet.locked}
                                            updateFlashcard={this.updateFlashcard}
                                            deleteFlashcard={this.deleteFlashcard} />

                                    }
                                )
                            }

                            <div className="clearfix"></div>

                        </div>


                        <FlashcardSetControls removeFlashcardSet={this.removeFlashcardSet}
                                              addFlashcard={this.addFlashcard}{...this.props}/>

                    </div>

                </div>

            );

        } else {

            return (

                <div className="col-md-10 col-md-offset-1">

                    <div className="flashcardset-display">

                        {
                            this.state.editName ?

                                <EditFlashcardsetName finished={() => {
                                    this.setState({editName: false})
                                }} flashcardSet={this.props.flashcardSet}/>

                                :

                                <h4 className={this.props.flashcardSet.locked ? 'flashcardset-header' : 'flashcardset-header name-editable'}>
                            <span className="edit-flashcardset-click"
                                  onClick={() => {
                                      !this.props.flashcardSet.locked && this.setState({editName: true})
                                  }}>{this.props.flashcardSet.name}</span>
                                </h4>
                        }

                        <p className="alert alert-info">
                            There are no flash cards
                        </p>

                        <FlashcardSetControls removeFlashcardSet={this.removeFlashcardSet}
                                              addFlashcard={this.addFlashcard}{...this.props}/>

                    </div>

                </div>

            )

        }

    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber,
        user: state.user,
        flashcardSet: state.flashcardSet
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        getFlashcardSet: (flashcardSetID) => dispatch(flashcardSetActions.getFlashcardSetRequest(flashcardSetID)),
        addFlashcardRequest: (flashcardSetID) => dispatch(flashcardSetActions.addFlashcardRequest(flashcardSetID)),
        updateFlashcardRequest : (flashcardSetID, flashcardID, updatedFlashcard) => dispatch(flashcardSetActions.updateFlashcardRequest(flashcardSetID, flashcardID, updatedFlashcard)),
        deleteFlashcardRequest: (flashcardSetID, flashcardID) => dispatch(flashcardSetActions.deleteFlashcardRequest(flashcardSetID, flashcardID)),
        updateFlashcardSet: (flashcardSetID, updatedFlashcardSet) => dispatch(flashcardSetActions.updateFlashcardSetRequest(flashcardSetID, updatedFlashcardSet)),
        deleteFlashcardSet: (flashcardSetID) => dispatch(flashcardSetActions.deleteFlashcardSetRequest(flashcardSetID))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FlashcardSetDisplay));

