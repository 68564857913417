import React from 'react';
import debounce from 'debounce';

import AceEditor from 'react-ace';
import PropTypes from 'prop-types';

import brace from 'brace';
import 'brace/mode/java';
import 'brace/theme/github';

export default class AceNote extends React.Component {

    constructor(props) {

        super(props);

        console.log(props.note);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.noteChanged = this.noteChanged.bind(this);

        // Note onChange event will only update the server every 500ms
        this.updateNote = debounce(this.updateNote, 500);

        this.removeNote = this.removeNote.bind(this);
        this.changeWidth = this.changeWidth.bind(this);
        this.toggleNoteEdit = this.toggleNoteEdit.bind(this);

        this.state = Object.assign({}, {note: this.props.note}, {saving: false, height : '150px'});

    }

    removeNote(ev) {

        ev.preventDefault();

        this.props.unsetNoteEditable();
        this.props.removeNote(this.props.note._id);

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    noteChanged(updatedCode){

        // Update the state in the actual editor
        this.setState(
            {
                saving: true,
                note : {
                    html : updatedCode
                }
            }
        );

        // Make debounced call to the server
        this.updateNote(updatedCode);

    }

    // Update the note on the server when the content changes
    updateNote(updatedCode) {

        const updatedNote = Object.assign({}, this.props.note, {html: updatedCode});
        
        this.props.updateNote(this.props.note._id, updatedNote).then(
            () => {

                // Show the 'saving' message for a second or it just becomes a meaningless blur.
                setTimeout(() => {
                    this.setState({saving: false});
                }, 1000)


            }
        );

    }

    changeWidth(increase = true){

        const cssClasses = [
            'col-md-4 col-sm-6 col-xs-12',
            'col-md-6 col-sm-6 col-xs-12',
            'col-md-12 col-sm-12 col-xs-12'
        ];

        const classIndex = cssClasses.indexOf(this.props.note.cssclass);

        if((increase && classIndex === cssClasses.length - 1) || !increase && classIndex === 0 ){
            return;
        }

        const newClass = cssClasses[increase ? classIndex + 1 : classIndex - 1],
            updatedNote = Object.assign({}, this.props.note, {cssclass : newClass});

        this.setState({saving : true});

        this.props.updateNote(this.props.note._id, updatedNote).then(
            () => {

                // Show the 'saving' message for a second or it just becomes a meaningless blur.
                setTimeout(() => {
                    this.setState({saving: false});
                }, 1000)


            }
        );

    }

    toggleNoteEdit(ev, editNote = true) {

        ev.preventDefault();

        // Set all notes to non editing state
        this.props.unsetNoteEditable();

        if(editNote){

            this.props.setNoteEditable(this.props.note._id);

        }

    }

    render() {

        if(!this.state.loaded || !this.props.note) return null;

        return (

            <div id={'sm-note-editor_' + this.props.note._id}
                 className={'sm-note-editor ' + this.props.note.cssclass + (this.props.note.editing ? ' editing-note' : '') }>

                {

                    <AceEditor
                        mode={this.props.note.acelanguage}
                        readOnly={this.props.worksheetLocked}
                        theme="github"
                        onFocus={(ev)=>{ ev.preventDefault(); !this.props.worksheetLocked && this.props.setNoteEditable(this.props.note._id) }}
                        onChange={this.noteChanged}
                        height={this.state.height}
                        minLines={5}
                        maxLines={50}
                        width='auto'
                        value={this.state.note.html}
                        editorProps={{$blockScrolling: true}}
                    />


                }

                <div className={this.props.note.editing ? 'note-editor-controls controls-fade-in' : 'note-editor-controls'}>

                    <div className="pull-left note-change-width">

                        <span onClick={()=>{this.changeWidth(false)}}
                              className="width-icon glyphicon glyphicon-minus note-decrease-width" aria-hidden="true"></span>
                        <span onClick={()=>{this.changeWidth(true)}}
                              className="width-icon glyphicon glyphicon-plus note-increase-width" aria-hidden="true"></span>

                    </div>

                    {
                        this.state.saving ?
                            <span className='controls-saving'>saving...</span> :
                            <span className='controls-saving'>saved</span>
                    }

                    <div className='pull-right btn-group finish-edit'>

                        <button type="button"
                                className="icon-finish-edit btn btn-default"
                                onClick={()=>{this.toggleNoteEdit(event, false) }} >

                            <span className="glyphicon glyphicon-ok" aria-hidden="true"></span>

                        </button>

                    </div>

                    {/* Remove note dropdown */}
                    <div className="btn-group pull-right icon-remove-note">

                        <button type="button"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            <span className="glyphicon glyphicon-trash pull-right"
                                  aria-hidden="true"></span>
                        </button>

                        <ul className="dropdown-menu note-delete-confirm">
                            <li onClick={this.removeNote}>confirm</li>
                        </ul>

                    </div>

                    <div className="clearfix"></div>

                </div>

            </div>

        )

    }

}

AceNote.propTypes = {
    note: PropTypes.shape(
        {
            _id: PropTypes.string.isRequired
        }
    )
};



