
// All user auth is token based, the token payload contains the user profile
// - purely for display. Any restricted server side resources will validate the token.

const jwtDecode = require('jwt-decode');

export function getUserInfo(){

    const token =  localStorage.getItem('melon-token');

    if(!token) return null;

    const payload = jwtDecode(token);

    if(!payload) throw new Error('No user info');

    return payload.user;

}