
import * as throbberActions from './throbberActions';
import axios from 'axios';

const config = require('../../../config');

const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY',
    UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS',
    DELETE_SEARCH_RESULTS = 'DELETE_SEARCH_RESULTS';

export { UPDATE_SEARCH_QUERY, UPDATE_SEARCH_RESULTS, DELETE_SEARCH_RESULTS };

export function updateSearchResultsRequest(searchQuery){

    return(dispatch, getState)=>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(updateSearchQuery(searchQuery));
        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.get(config.server_url + '/api/v1/search', { params : { keyword : searchQuery }}).then(
                    (reply)=>{

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        dispatch(throbberActions.hideThrobber());
                        dispatch(updateSearchResults(response.results));

                        resolve()

                    }
                ).catch(
                    (err)=>{

                        console.error(err.message);
                        throbberActions.showError(err.message);
                        reject();
                    }
                )

            }
        )

    }

}

export function updateSearchQuery(searchQuery){

    return{
        type : UPDATE_SEARCH_QUERY,
        query : searchQuery
    }

}

export function updateSearchResults(searchResults){

    return {
        type : UPDATE_SEARCH_RESULTS,
        results : searchResults
    }

}

export function deleteSearchResults(){

    return {
        type : DELETE_SEARCH_RESULTS,
        searchResults: ''
    }

}
