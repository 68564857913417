
import * as throbberActions from './throbberActions';
import * as noteActions from './noteActions';

import axios from 'axios';
import config from '../../../config';

const ADD_WORKSHEET = 'ADD_WORKSHEET',
    UPDATE_WORKSHEET = 'UPDATE_WORKSHEET',
    ADD_NOTE_TO_WORKSHEET = 'ADD_NOTE_TO_WORKSHEET',
    UPDATE_NOTE_ON_WORKSHEET = 'UPDATE_NOTE_ON_WORKSHEET',
    REMOVE_NOTE_FROM_WORKSHEET = 'REMOVE_NOTE_FROM_WORKSHEET',
    SET_NOTE_EDITABLE = 'SET_NOTE_EDITABLE',
    UNSET_NOTE_EDITABLE = 'UNSET_NOTE_EDITABLE',
    DELETE_WORKSHEET = 'DELETE_WORKSHEET',
    LOCK_WORKSHEET = 'LOCK_WORKSHEET',
    UNLOCK_WORKSHEET = 'UNLOCK_WORKSHEET';

export {
    ADD_WORKSHEET, UPDATE_WORKSHEET, ADD_NOTE_TO_WORKSHEET, UPDATE_NOTE_ON_WORKSHEET, REMOVE_NOTE_FROM_WORKSHEET,
    SET_NOTE_EDITABLE, UNSET_NOTE_EDITABLE, DELETE_WORKSHEET, LOCK_WORKSHEET, UNLOCK_WORKSHEET
};

// Thunks - api requests related to worksheets
export function getWorksheetRequest(worksheetID){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.get(config.server_url + '/api/v1/worksheet/' + worksheetID).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success){

                            dispatch(throbberActions.showError(response.msg));
                            return reject(response.msg);
                        }

                        dispatch(addWorksheet(response.worksheet));
                        resolve();

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));

                        reject(err.message);

                    }
                );

            }
        );

    }

}

export function addWorksheetRequest(categoryid){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.post(config.server_url + '/api/v1/worksheet', {categoryid, worksheet : { name : 'new worksheet'} }).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        dispatch(addWorksheet(response.worksheet));
                        resolve(response.worksheet._id);

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));

                    }
                );

            }
        )


    }

}

export function updateWorksheetRequest(worksheetID, updatedWorksheet){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.put(config.server_url + '/api/v1/worksheet/' + worksheetID, {worksheet: updatedWorksheet}).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success){

                            dispatch(throbberActions.showError(response.msg));
                            return reject(response.msg);
                        }

                        dispatch(updateWorksheet(worksheetID, response.worksheet));
                        resolve();

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));
                        reject(err.message);

                    }
                );

            }
        );

    }

}

export function deleteWorksheetRequest(worksheetID){

    return (dispatch, getState)=>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                // Server will delete all child notes as well as the worksheet
                axios.delete(config.server_url + '/api/v1/worksheet/' + worksheetID).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        dispatch(deleteWorksheet());
                        resolve();

                    }
                ).catch(
                    (err)=>{

                        dispatch(throbberActions.showError(err.msg));
                        reject(err.message);

                    }
                )

            }
        )

    }

}

// Create a new note via the notes api, add the note to the parent worksheet's state
export function addNoteToWorksheetRequest(worksheetID, newNote = null){

    return (dispatch, getState)=>{

        return new Promise(
            (resolve, reject)=>{

                // Create the note via the note api
                dispatch(noteActions.addNoteRequest(worksheetID, newNote)).then(
                    (note)=>{

                        // Now add the note to the worksheet and update the state
                        dispatch(addNoteToWorksheet(note));
                        resolve();

                    }
                )

            }
        );



    }

}

// Remove the note via the API, then update the worksheet state
export function removeNoteFromWorksheetRequest(noteID){

    return (dispatch, getState)=>{

        return new Promise(
            (resolve, reject)=>{

                // Create the note via the note api
                dispatch(noteActions.deleteNoteRequest(noteID)).then(
                    ()=>{

                        // Now update the worksheet state
                        dispatch(removeNoteFromWorksheet(noteID));
                        resolve();

                    }
                )

            }
        );



    }

}

export function updateNoteRequest(noteID, updatedNote){

    return (dispatch, getState)=>{

        return new Promise(
            (resolve, reject)=>{

                // Update the note via the note api
                dispatch(noteActions.updateNoteRequest(noteID, updatedNote)).then(
                    ()=>{

                        // Now update the worksheet state
                        dispatch(updateNoteOnWorksheet(noteID, updatedNote));
                        resolve();

                    }
                )

            }
        );



    }

}

// Synchronous methods
export function addWorksheet(worksheet){

    return {
        type : ADD_WORKSHEET,
        worksheet
    }

}

// Just sets the worksheet as editable in the UI
export function editWorksheet(worksheetID){

    return {
        type : EDIT_WORKSHEET,
        worksheetID
    }

}

export function updateWorksheet(worksheetID, worksheet){

    return {
        type : UPDATE_WORKSHEET,
        worksheetID,
        worksheet
    }

}

export function deleteWorksheet(worksheetID){

    return{
        type : DELETE_WORKSHEET,
        worksheetID
    }

}

export function updateNoteOnWorksheet(noteID, updatedNote){

    return {
        type : UPDATE_NOTE_ON_WORKSHEET,
        noteID,
        updatedNote
    }

}

export function addNoteToWorksheet(note){

    return {
        type : ADD_NOTE_TO_WORKSHEET,
        note
    }

}

export function removeNoteFromWorksheet(noteID){

    return {
        type : REMOVE_NOTE_FROM_WORKSHEET,
        noteID
    }

}

export function setNoteEditable(noteID){

    return {
        type: SET_NOTE_EDITABLE,
        noteID
    }

}

// Return all notes to 'unediting' state
export function unsetNoteEditable(){

    return {
        type : UNSET_NOTE_EDITABLE
    }

}

