
import Immutable from 'seamless-immutable';

import {
    ADD_FLASHCARDSET,
    UPDATE_FLASHCARDSET,
    ADD_FLASHCARD_TO_FLASHCARDSET,
    REMOVE_FLASHCARD_FROM_FLASHCARDSET,
    LOCK_FLASHCARDSET,
    UNLOCK_FLASHCARDSET,
    SET_FLASHCARD_EDITABLE,
    UNSET_FLASHCARD_EDITABLE,
    DELETE_FLASHCARDSET, UPDATE_FLASHCARD_ON_FLASHCARDSET
} from '../actions/flashcardSetActions';

// Actions related to a single flashcardset. See flashcardsetListReducers for bulk actions with flashcardsets
function flashcardSet(state = {}, action){

    const immState = Immutable(state);

    switch(action.type){

        case ADD_FLASHCARDSET :

            return action.flashcardSet;

        case UPDATE_FLASHCARDSET :

            return Object.assign({}, immState, action.flashcardSet);

        case SET_FLASHCARD_EDITABLE:

            newFlashcards = immState.flashcards.map(flashcard => (Object.assign({}, flashcard, {editing : (flashcard._id === action.flashcardID)}) ));
            return Object.assign({}, immState, {flashcards: newFlashcards});

        case UNSET_FLASHCARD_EDITABLE:

            newFlashcards = immState.flashcards.map(flashcard => (Object.assign({}, flashcard, {editing : false}) ));
            return Object.assign({}, immState, {flashcards: newFlashcards});


        case DELETE_FLASHCARDSET:

            return {};

    }

    return immState;

}

export {flashcardSet}

