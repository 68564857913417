


import Immutable from 'seamless-immutable';

import {ADD_SETTINGS} from '../actions/settingsActions';

function settings(state = {}, action){

    const immState = Immutable(state);

    switch(action.type){

        case ADD_SETTINGS:
            return action.settings



    }

    return immState;

}

export {settings}

