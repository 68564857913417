
// Remember a reducer takes the state, applies an action, and returns the new state
// Remember you need an initial state

import Immutable from 'seamless-immutable';

import { SHOW_THROBBER, HIDE_THROBBER, SHOW_MESSAGE, throbberTypes } from '../actions/throbberActions';

const init = {
    showThrobber : false,
    throbberType : throbberTypes.INFO,
    message : ''
};

function throbber(state = init, action){

    const immState = Immutable(state);

    switch(action.type){

        case SHOW_THROBBER :

            return {
                showThrobber: true,
                throbberType: throbberTypes.INFO,
                showLoading : action.showLoading || true,
                message : action.message || ''
            };

        case HIDE_THROBBER :

            return {
                showThrobber : false
            };

        case SHOW_MESSAGE :

            return {
                showThrobber : true,
                throbberType : throbberTypes.INFO,
                showLoading : action.showLoading || true,
                message : action.message || ''
            };

        case 'SHOW_ERROR' :

            return {
                showThrobber : true,
                throbberType : throbberTypes.ERROR,
                showLoading : action.showLoading || true,
                message : action.message || ''
            }

    }

    return immState;
}

export {throbber};


