import React from 'react';
import UserDropdown from './UserDropdown';
import UnpaidInvoices from './UnpaidInvoices';

import {withRouter, Link} from 'react-router-dom';
import SearchBar from '../search/SearchBar';
import logo from '../../../public/img/logo-new.png';

import './header.less';

/*const Header = (props) => (

    <header>

        <Link className='logo' to="/">
            <img src={logo}/>
        </Link>

        {
            props.user &&

            <div className="header-right">

                <UserDropdown {...props}/>

                <SearchBar/>

                <UnpaidInvoices/>

            </div>

        }

    </header>

);*/

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false
        }

    }

    // Bizarrely, this is the only way to get the image to show when
    // doing login with Twitter
    componentDidMount() {
        this.setState({ready: true})
    }

    render() {

        return this.state.ready ?
            <header>

                {logo && <Link className='logo' to="/">
                    <img src='/img/logo-new.png'/>
                </Link>}

                {
                    this.props.user &&

                    <div className="header-right">

                        <UserDropdown {...this.props}/>

                        <SearchBar/>

                        <UnpaidInvoices/>

                    </div>

                }

            </header> : null
    }
}

export default withRouter(Header)
