
import * as throbberActions from './throbberActions';
import axios from 'axios';
import {showThrobber} from "./throbberActions";

const config = require('../../../config');

// Notes exist in the state only as a list in a parent worksheet. The only actions here are asynchronous thunks:
// api calls used dispatched by worksheet actions.

export function addNoteRequest(worksheetid, newnote = null){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.post(config.server_url + '/api/v1/note', {worksheetid, newnote  }).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        resolve(response.note);

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));

                    }
                );

            }
        )


    }

}

export function updateNoteRequest(noteID, updatedNote, showThrobber = false){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        showThrobber && dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.put(config.server_url + '/api/v1/note/' + noteID, { note: updatedNote }).then(
                    (reply)=>{

                        showThrobber && dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success){
                            showThrobber && dispatch(throbberActions.showError(response.msg));
                            return reject(response.msg);
                        }

                        resolve(response);

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        showThrobber && dispatch(throbberActions.showError(err.message));
                        return reject(err.message);

                    }
                );

            }
        );

    }

}

export function deleteNoteRequest(noteID){

    return (dispatch, getState)=>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        return new Promise(
            (resolve, reject)=>{

                dispatch(throbberActions.showThrobber());

                axios.delete(config.server_url + '/api/v1/note/' + noteID).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        resolve();

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));

                    }
                );

            }
        );


    }

}
