import React from 'react';

import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

import PropTypes from 'prop-types';

import * as categoryActions from '../../../state/actions/categoryActions';
import * as worksheetActions from '../../../state/actions/worksheetActions';
import * as flashcardSetActions from '../../../state/actions/flashcardSetActions';

import './category-display.less';

import EditCategoryName from '../EditCategoryName/EditCategoryName.jsx';
import CategoryTagManager from '../CategoryTagManager';


class CategoryDisplay extends React.Component {

    constructor(props) {

        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.addSubCategory = this.addSubCategory.bind(this);
        this.editCategory = this.editCategory.bind(this);
        this.removeCategory = this.removeCategory.bind(this);
        this.addWorksheet = this.addWorksheet.bind(this);
        this.addFlashcardSet = this.addFlashcardSet.bind(this);

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    // Add a subcategory to this one. Requires adding to the children array of this category
    // and creating a new category with this one as the parent.
    addSubCategory(ev) {

        ev.preventDefault();
        this.props.addSubcategoryRequest(this.props.category._id);

    }

    addWorksheet(ev) {

        ev.preventDefault();

        this.props.addWorksheetRequest(this.props.category._id).then(
            (worksheetID) => {

                // The worksheet has been created, just redirect to the edit worksheet page
                this.props.history.push('/worksheet/' + worksheetID);

            }
        )

    }

    addFlashcardSet(ev) {

        ev.preventDefault();
        this.props.addFlashcardSetRequest(this.props.category._id).then(
            (flashcardSetID) => {

                this.props.history.push('/flashcardset/' + flashcardSetID);

            }
        )

    }

    // Just makes the name editable
    editCategory(ev) {

        ev.preventDefault();
        this.props.editCategory(this.props.category._id);

    };

    removeCategory(ev) {

        ev.preventDefault();
        this.props.deleteCategoryRequest(this.props.category._id);

    }

    // Render category and recursively render its children. Each child needs to reference the category in the redux store,
    // not the one listed in the category's children array.
    render() {
        return (

            <div className={this.props.category.parent ? '' : 'col-md-4 col-sm-6 col-xs-12 category-container'}>

                <div className={this.props.category.parent ? 'category-child-display' : 'category-display'}>

                    <h4 className="category-header">

                        {
                            this.props.category.editing ?

                                <EditCategoryName category={this.props.category}/>

                                : <span className="edit-category-click"
                                        onClick={this.editCategory}>{this.props.category.name}</span>
                        }

                        <div className='category-buttons'>

                            <CategoryTagManager category={this.props.category} />

                            <div className="btn-group category-delete-dropdown">

                                <button type="button" className="dropdown-toggle" data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        <span className="glyphicon glyphicon-trash pull-right icon-remove-category"
                                              aria-hidden="true"></span>
                                </button>

                                <ul className="dropdown-menu category-delete-confirm">
                                    <li onClick={this.removeCategory}>confirm</li>
                                </ul>

                            </div>

                            <div className="btn-group category-add-dropdown">

                                <button type="button" className="dropdown-toggle" data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                    <span className="glyphicon glyphicon-plus-sign pull-right"
                                          aria-hidden="true"></span>
                                </button>

                                <ul className="dropdown-menu">
                                    <li><a href="#" onClick={this.addSubCategory}>sub-category</a></li>
                                    <li><a href="#" onClick={this.addWorksheet}>worksheet</a></li>
                                    <li><a href="#" onClick={this.addFlashcardSet}>flashcard set</a></li>
                                </ul>

                            </div>

                        </div>

                    </h4>

                    {

                        <ul className="worksheet-list">

                            {
                                this.props.category.worksheets &&
                                this.props.category.worksheets.length ?
                                    this.props.category.worksheets.map(
                                        (worksheet, i) => (
                                            <li key={i}>
                                                <Link to={'/worksheet/' + worksheet._id}>
                                                    <span className="glyphicon glyphicon-edit"
                                                          aria-hidden="true"></span>
                                                    {worksheet.name}
                                                </Link>
                                            </li>
                                        )
                                    )

                                    : null

                            }

                            {
                                this.props.category.flashcardsets &&
                                this.props.category.flashcardsets.length ?
                                    this.props.category.flashcardsets.map(
                                        (flashcardset, i) => (
                                            <li key={i}>
                                                <Link to={'/flashcardset/' + flashcardset._id}>
                                            <span className="glyphicon glyphicon-modal-window"
                                                  aria-hidden="true"></span>
                                                    {flashcardset.name}
                                                </Link>
                                            </li>
                                        )
                                    )

                                    : null


                            }

                        </ul>

                    }

                    {
                        this.props.category.children &&
                        this.props.category.children.map(
                            (childID, i) => {

                                {/* All categories - child or not - are in the this.props.categories array */
                                }
                                for (let i = 0; i < this.props.categories.length; i++) {

                                    if (childID === this.props.categories[i]._id) {

                                        return <CategoryContainer key={i} category={this.props.categories[i]}/>

                                    }

                                }

                            }
                        )

                    }


                </div>

                <div className="clearfix"></div>


            </div>

        )


    }

}

CategoryDisplay.propTypes = {
    category: PropTypes.shape(
        {
            _id: PropTypes.string.isRequired
        }
    ),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired
};

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber,
        categories: state.categories,
        user: state.user
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        editCategory: (categoryID) => dispatch(categoryActions.editCategory(categoryID)),
        deleteCategoryRequest: (categoryID) => dispatch(categoryActions.deleteCategoryRequest(categoryID)),
        addSubcategoryRequest: (parentCategoryID) => dispatch(categoryActions.addSubcategoryRequest(parentCategoryID)),
        addWorksheetRequest: (parentCategoryID) => dispatch(worksheetActions.addWorksheetRequest(parentCategoryID)),
        addFlashcardSetRequest: (parentCategoryID) => dispatch(flashcardSetActions.addFlashcardSetRequest(parentCategoryID))
    }
};

// Necessary to create a parent container so that redux methods / properties can be passed to recursively
// rendered CategoryDisplay components
const CategoryContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryDisplay));

export default CategoryContainer;


