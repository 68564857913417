
import * as throbberActions from './throbberActions';
import axios from 'axios';

import config from '../../../config';

const GET_SETTINGS = 'GET_SETTINGS',
    ADD_SETTINGS = 'ADD_SETTINGS';

export {ADD_SETTINGS};

// Thunks - api requests related to categories
export function getSettingsRequest(){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return axios.get(config.server_url + '/api/v1/settings').then(
            (reply)=>{

                dispatch(throbberActions.hideThrobber());

                const response = reply.data;

                if(!response.success)
                    return dispatch(throbberActions.showError(response.msg));

                dispatch(addSettings(response.settings));

            }
        ).catch(
            (err)=>{

                console.error('ALARRRRMMM!! ' + err.message);

                dispatch(throbberActions.showError(err.message));

            }
        );

    }

}

export function addSettings(settings){
    return {
        type: ADD_SETTINGS,
        settings
    }
}
