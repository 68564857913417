
import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import * as throbberActions from '../../../state/actions/throbberActions';
import * as userActions from '../../../state/actions/userActions';

import GithubLogin from "../GithubLogin";
import TwitterLogin from "../TwitterLogin";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import config from "../../../../config";
import {FacebookLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import {FacebookButton, GoogleButton} from "../SocialButtons";
import {GoogleLogin} from "react-google-login";
import axios from "axios";

import './social-login-container.less';

class SocialLoginContainer extends React.Component{

    constructor(props){
        super(props);

        this.facebookResponse = this.facebookResponse.bind(this);
        this.googleResponse = this.googleResponse.bind(this);

    }

    async facebookResponse(response) {

        try {
            const {data} = await axios.post(`${config.server_url}/auth/facebook/${response.accessToken}`);

            this.setState({registrationSuccessful: true});
            this.props.userRegistered(data.user);

            // Store access token
            localStorage.setItem('melon-token', data.token);

            setTimeout(
                () => {
                    this.props.history.push('/');
                }, 2000
            );

        } catch (err) {
            console.error(err);
        }


    }

    async googleResponse(response) {

        try {
            const {data} = await axios.post(`${config.server_url}/auth/google`, {tokenid: response.tokenId});

            this.setState({registrationSuccessful: true});
            this.props.userRegistered(data.user);

            // Store access token
            localStorage.setItem('melon-token', data.token);

            setTimeout(
                () => {
                    this.props.history.push('/');
                }, 2000
            );

        } catch (err) {
            console.error(err);
        }

    }

    render(){

        return(

            <div className='social-logins'>

                <div className='social-login-row'>

                    <GithubLogin className='social-login-button social-login-github' userRegistered={this.props.userRegistered}/>

                    <TwitterLogin className='social-login-button social-login-twitter' userRegistered={this.props.userRegistered}/>

                </div>

                <div className='social-login-row'>

                    <FacebookLogin
                        appId={config.facebook_app_id}
                        fields="name,email,picture"
                        callback={this.facebookResponse}
                        render={renderProps => <FacebookButton onClick={renderProps.onClick}/>}
                    />

                    <GoogleLogin
                        clientId={config.google_client_id}
                        buttonText="Login"
                        onSuccess={this.googleResponse}
                        onFailure={this.onFailure}
                        render={renderProps => (
                            <GoogleButton onClick={renderProps.onClick}/>
                        )}
                    />

                </div>

            </div>
        )
    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        throbber: state.throbber
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        showThrobber: () => dispatch(throbberActions.showThrobber()),
        showError: (msg) => dispatch(throbberActions.showError(msg)),
        hideThrobber: () => dispatch(throbberActions.hideThrobber()),
        userRegistered: (user) => dispatch(userActions.userRegistered(user)),
        userLoggedIn: (user) => dispatch(userActions.userLoggedIn(user))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SocialLoginContainer))