
import Immutable from 'seamless-immutable';

import {ADD_FLASHCARDSETS, ADD_FLASHCARDSET_TO_LIST, DELETE_FROM_FLASHCARDSET, DELETE_FLASHCARDSET } from "../actions/flashcardSetListActions";

// Actions related to a single flashcardset. See 
function flashcardsetList(state = [], action){

    const immstate = Immutable(state);

    switch (action.type) {

        case ADD_FLASHCARDSETS:

            return [...immstate, ...action.flashcardsets];

        case ADD_FLASHCARDSET_TO_LIST:

            return [...immstate, action.flashcardset];

        case DELETE_FROM_FLASHCARDSET:

            return immstate.filter(set => set._id !== action.flashcardsetid)

        case DELETE_FLASHCARDSET:

            return [];

    }

    return immstate;

}

export {flashcardsetList}

