
// Just a simple debounce function
const debounce = (wait, fn) => {

    let timeout = null;

    return function() {

        const functionCall = () => fn.apply(this, arguments);

        clearTimeout(timeout);
        timeout = setTimeout(functionCall, wait);
    }
};

export {debounce};