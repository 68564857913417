import React from 'react';

import {connect} from 'react-redux';
import Masonry from '../../SmoothMasonry';

import * as throbberActions from '../../../state/actions/throbberActions';
import * as categoryActions from '../../../state/actions/categoryActions';

import CategoryDisplay from '../CategoryDisplay';
import AddCategory from '../AddCategory';
import AddTag from '../../tag/AddTag';
import FilterCategoryByTag from '../FilterCategoryByTag';
import * as auth from '../../../lib/userAuth';
import TestMe from "../../TestMe";

import './category-list.less';

class CategoryList extends React.Component {

    constructor(props) {

        super(props);

    }

    componentDidMount() {

        // Redirect if the user is not authenticated
        if (!auth.getUserInfo()) {

            return this.props.history.push('/login');
        }

        // Load categories into state via redux
        this.props.getCategoriesRequest({params: {activeOnly: true}});

    }

    render() {

        return (

            <div className="col-md-10 col-md-offset-1">

                <div className="category-list row fade-in">

                    <div className='category-tag-test-header'>

                        <div className='add-category-container'>

                            <h3 className='header-text'>Categories</h3>

                            <AddCategory/>

                        </div>

                        <div className='tag-manager-test'>

                            <AddTag/>

                            <TestMe/>

                        </div>

                    </div>

                    <FilterCategoryByTag/>

                    {
                        !this.props.categories.length &&
                        <p className="alert alert-info">
                            You have no categories. Enter a new category in the textbox above.
                        </p>
                    }

                    {
                        !!this.props.categories.length &&

                        <Masonry>

                            <div className='row masonry-container'>

                                {
                                    this.props.categories.map(
                                        (category, i) => (

                                            category && category.parent ? null
                                                :
                                                <CategoryDisplay key={i} category={category}/>

                                        )
                                    )
                                }

                            </div>

                        </Masonry>
                    }

                </div>

            </div>

        )


    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber,
        categories: state.categories,
        user: state.user
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.showThrobber
        showThrobber: () => dispatch(throbberActions.showThrobber()),
        showError: (msg) => dispatch(throbberActions.showError(msg)),
        hideThrobber: () => dispatch(throbberActions.hideThrobber()),
        getCategoriesRequest: (filterObject = {}) => dispatch(categoryActions.getCategoriesRequest(filterObject))
    }
};

export {CategoryList}; // For testing

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)

