
import Immutable from 'seamless-immutable';

import {
    ADD_CATEGORIES,
    ADD_CATEGORY,
    EDIT_CATEGORY,
    UPDATE_CATEGORY,
    REMOVE_CATEGORY} from '../actions/categoryActions';

function categories(state = [], action){

    const immState = Immutable(state);

    switch(action.type){

        case ADD_CATEGORIES:

            return action.categories;

        case ADD_CATEGORY :

            return [
                ...immState,
                action.category
            ];

        case EDIT_CATEGORY :

            return immState.map(
                (category)=>{

                    if(category._id === action.categoryID){
                        return Object.assign({}, category, {editing : true})
                    }

                    return category;
                }
            );


        case UPDATE_CATEGORY :

            return immState.map(
                (category)=>{

                    if(category._id === action.categoryID){

                        category = action.category;
                        category.editing = false;
                    }

                    return category;
                }
            );

        case REMOVE_CATEGORY:

            return immState.filter(
                (category)=> category._id !== action.categoryID
            )

    }

    return immState;

}

export {categories}

