import React from 'react';
import {TwitterLoginButton} from "react-social-login-buttons";
import {TwitterButton} from "../SocialButtons";
import {withRouter} from 'react-router-dom';

import axios from 'axios';
import config from '../../../../config';
import {getUserInfo} from "../../../lib/userAuth";

let win = null; // Minibrowser window

const miniBrowser = (windowUrl, callBack) => {

    window.windowType = 'twitter';

    const width = parseInt(screen.width * 0.4),
        height = parseInt(screen.height * 0.8);

    let winl = parseInt((screen.width - width) / 2),
        wint = parseInt((screen.height - height) / 2);

    if (winl < 0) winl = 0;

    var settings = 'height=' + height + ',';
    settings += 'width=' + width + ',';
    settings += 'top=' + wint + ',';
    settings += 'left=' + winl + ',';
    settings += 'resizable,scrollbars';

    win = window.open(windowUrl, 'Authorise Smoothbook with Twitter', settings);
    win.window.focus();

    const interval = window.setInterval(function () {
        try {

            if (win == null || win.closed) {
                window.clearInterval(interval);
                callBack()

            }
        } catch (e) {
        }
    }, 1000);


};

class TwitterLogin extends React.Component {

    constructor(props) {
        super(props);

        this.getRequestToken = this.getRequestToken.bind(this)
    }

    // Retrieve Twitter request token from the backend, launch minibrowser;
    async getRequestToken() {

        try {

            miniBrowser(config.twitter.request_token_uri,
                () => {

                    // Minibrowser has been closed; JWT token stored.
                    const user = getUserInfo();
                    this.props.userRegistered(user);
                    this.props.history.push('/');

                }
            )

        } catch (err) {

            console.error(err.message);

        }

    }

    render() {

        return <TwitterButton onClick={this.getRequestToken}/>
    }


}

export default withRouter(TwitterLogin)