

import React from 'react';
import {Link, withRouter} from 'react-router-dom';

const FlashcardSetControls = (props) => {

    if(!props.flashcardSet) return null;

    return (
        <div className="flashcardset-controls">

            <button disabled={props.flashcardSet.locked} className="btn btn-primary" onClick={ props.addFlashcard }>
                add flashcard
            </button>

            <Link className="btn btn-default" to="/">go back</Link>

            {
                props.flashcardSet.locked ?

                    <button onClick={()=>{props.updateFlashcardSet(props.flashcardSet._id, {locked : !props.flashcardSet.locked})}}
                            className="btn btn-danger">Unlock flashcard set</button> :
                    <button onClick={()=>{props.updateFlashcardSet(props.flashcardSet._id, {locked : !props.flashcardSet.locked})}}
                            className="btn btn-success">Lock flashcard set</button>

            }


            <div className="btn-group pull-right flashcardset-delete-dropdown">

                <button disabled={props.flashcardSet.locked}
                        type="button"
                        className="dropdown-toggle btn btn-danger"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                    Delete flashcard set
                </button>

                <ul className="dropdown-menu flashcardset-delete-confirm">
                    <li onClick={(ev)=>{props.removeFlashcardSet(ev, props.flashcardSet._id)}}>delete this flashcard set and all flashcards</li>
                </ul>

            </div>
        </div>
    )

};

export default FlashcardSetControls;