
import React from 'react';
import {Link} from 'react-router-dom';

export default function NotFound(){

    return (

        <p className="page-not-found alert alert-info">

            Sorry but this page was not found.
            <Link to="/"><span style={{color: 'white', textDecoration : 'underline', display : 'block'}}>Click here to continue</span></Link>


        </p>
    )


}