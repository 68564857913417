
import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import config from '../../../../config';

import axios from 'axios';

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            resetError: '',
            resetSuccess: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitReset = this.submitReset.bind(this);
    }

    async submitReset(ev){

        ev.preventDefault();

        try{

            const {data} = await axios.post(`${config.server_url}/auth/forgot-password`, {email: this.state.email});

            if(!data.success){
                this.setState({resetError: data.msg});

                setTimeout(
                    ()=>{
                        this.setState({resetError: ''});
                    }, 3000
                );

                return;
            }

            // Success
            this.setState({resetSuccess: true})


        }catch (err) {

            this.setState({resetError: err.message});

            setTimeout(
                ()=>{
                    this.setState({resetError: ''});
                }, 3000
            )

        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    render() {

        return (
            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2"><h2>Forgot Password</h2>

                <form onSubmit={this.submitReset}>
                    <div className="form-group"><label>Please enter your email address</label>
                        <input type="email"
                               required={true}
                               className="form-control"
                               onChange={this.handleInputChange}
                               value={this.state.email}
                               name="email" />
                    </div>

                    {
                        this.state.resetSuccess &&
                        <p className='alert alert-success'>
                            An email has been sent to your email address with instructions on how to reset your password
                        </p>
                    }

                    {
                        this.state.resetError &&
                         <p className='alert alert-danger'>
                             Sorry but there was an error with this request: {this.state.resetError}
                         </p>
                    }

                    <div className="form-group">
                        <Link to='/login'>back to login page</Link>
                        <button type="submit" className="btn btn-primary pull-right">submit</button>
                        <div className="clearfix"></div>
                    </div>
                </form>
            </div>
        )

    }

}

export default withRouter(ForgotPassword)
