import React from 'react';
import {connect} from 'react-redux';
import * as tagActions from '../../../state/actions/tagActions';
import * as categoryActions from '../../../state/actions/categoryActions';

import './filter-category-by-tag.less';

class FilterCategoryByTag extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tags: []
        };

        this.clearTags = this.clearTags.bind(this);
    }

    componentDidMount() {

        this.props.getTags().then(
            ()=>{
                this.setState({tags: this.props.tags})
            }
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps.tags) !== JSON.stringify(this.props.tags)) {
            this.setState({tags: this.props.tags})
        }

    }

    toggleFilter(tagID, clearTags = false) { // Toggle whether the tag is an active filter or not.

        this.setState(
            {
                tags: this.state.tags.map(
                    taggy => {

                        if (taggy._id === tagID) {
                            taggy.active = !taggy.active
                        }

                        return taggy;
                    }
                )
            },
            () => {

                this.props.getCategories(
                    {
                        params: {
                            tags: this.state.tags.reduce(
                                (accum, tag) => {
                                    return tag.active ? [...accum, tag._id] : accum
                                }, []),
                            persist: true,
                            activeOnly: true
                        }
                    }
                );

            }
        )

    }

    clearTags(){

        this.props.getCategories(
            {
                params: {
                    tags: [],
                    persist: true,
                    activeOnly: true
                }
            }
        ).then(
            ()=>{

                this.setState({tags: this.state.tags.map(tag =>({...tag, active: false}))})

            }
        );

    }

    render() {

        if (!this.state.tags.length)
            return null;

        return (
            <div className='filter-tags-list'>

                <span className='filter-by-tag-label'>Filter by tag:</span>

                {
                    this.state.tags.map(
                        (tag, i) => (
                            <button key={i}
                                    className={tag.active ? 'btn btn-success btn-sm active' : 'btn btn-success btn-sm'}
                                    onClick={() => {
                                        this.toggleFilter(tag._id)
                                    }}>
                                {tag.active && <span className="glyphicon glyphicon-ok" aria-hidden="true"></span>}
                                {tag.name}
                            </button>
                        )
                    )
                }

                <button title='clear active tags' className='clear-tags' onClick={this.clearTags}>
                    <span className='glyphicon glyphicon-remove'></span>
                    clear
                </button>

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        tags: state.tags.tags
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTags: () => dispatch(tagActions.getTagsRequest()),
        getCategories: (filterObject = {}) => dispatch(categoryActions.getCategoriesRequest(filterObject))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterCategoryByTag)
