
import * as throbberActions from './throbberActions';

import axios from 'axios';
import config from '../../../config';

const ADD_FLASHCARDSET = 'ADD_FLASHCARDSET',
    UPDATE_FLASHCARDSET = 'UPDATE_FLASHCARDSET',
    ADD_FLASHCARD_TO_FLASHCARDSET = 'ADD_FLASHCARD_TO_FLASHCARDSET',
    SET_FLASHCARD_EDITABLE = 'SET_FLASHCARD_EDITABLE',
    UNSET_FLASHCARD_EDITABLE = 'UNSET_FLASHCARD_EDITABLE',
    DELETE_FLASHCARDSET = 'DELETE_FLASHCARDSET',
    LOCK_FLASHCARDSET = 'LOCK_FLASHCARDSET',
    UNLOCK_FLASHCARDSET = 'UNLOCK_FLASHCARDSET';

export {
    ADD_FLASHCARDSET, UPDATE_FLASHCARDSET, ADD_FLASHCARD_TO_FLASHCARDSET,
    SET_FLASHCARD_EDITABLE, UNSET_FLASHCARD_EDITABLE, DELETE_FLASHCARDSET, LOCK_FLASHCARDSET, UNLOCK_FLASHCARDSET
};

// Thunks - api requests related to flashcardSets
export function getFlashcardSetRequest(flashcardSetID){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.get(config.server_url + '/api/v1/flashcardset/' + flashcardSetID).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success){

                            dispatch(throbberActions.showError(response.msg));
                            return reject(response.msg);
                        }

                        dispatch(addFlashcardSet(response.flashcardset));
                        resolve();

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));

                        reject(err.message);

                    }
                );

            }
        );

    }

}

export function addFlashcardSetRequest(categoryid){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.post(config.server_url + '/api/v1/flashcardSet', {categoryid, defaultflashcard : true, flashcardset : { name : 'new flashcardSet'} }).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        dispatch(addFlashcardSet(response.flashcardset));
                        resolve(response.flashcardset._id);

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));

                    }
                );

            }
        )


    }

}

export function updateFlashcardSetRequest(flashcardSetID, updatedFlashcardSet){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.put(config.server_url + '/api/v1/flashcardSet/' + flashcardSetID, {flashcardset: updatedFlashcardSet}).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success){

                            dispatch(throbberActions.showError(response.msg));
                            return reject(response.msg);
                        }

                        dispatch(updateFlashcardSet(flashcardSetID, response.flashcardset));
                        resolve();

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));
                        reject(err.message);

                    }
                );

            }
        );

    }

}

export function deleteFlashcardSetRequest(flashcardSetID){

    return (dispatch, getState)=>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                // Server will delete all child flashcards as well as the flashcardSet
                axios.delete(config.server_url + '/api/v1/flashcardSet/' + flashcardSetID).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        dispatch(deleteFlashcardSet());
                        resolve();

                    }
                ).catch(
                    (err)=>{

                        dispatch(throbberActions.showError(err.msg));
                        reject(err.message);

                    }
                )

            }
        )

    }

}

// FLASHCARD ACTIONS
// All flashcard methods are done via the flashcardset api
export function addFlashcardRequest(flashcardSetID, newFlashCard = {}){

    return (dispatch, getState)=>{

        return new Promise(
            (resolve, reject)=>{

                const melonToken = localStorage.getItem('melon-token');
                axios.defaults.headers.common['x-access-token'] = melonToken;

                dispatch(throbberActions.showThrobber());

                // Flashcards need to be added to the flashcard set so do it via the flashcard set api.
                axios.post(config.server_url + '/api/v1/flashcardset/flashcard/' + flashcardSetID, {flashcard : newFlashCard}).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        dispatch(updateFlashcardSet(flashcardSetID, response.flashcardset));
                        resolve(response.flashcardset._id);

                    }
                ).catch(
                    (err)=>{

                        dispatch(throbberActions.showError(err.message));
                        reject(err.message);

                    }
                )


            }
        );

    }

}

export function updateFlashcardRequest(flashcardSetID, flashcardID, flashcardUpdates){

    return (dispatch, getState)=>{

        return new Promise(
            (resolve, reject)=>{

                const melonToken = localStorage.getItem('melon-token');
                axios.defaults.headers.common['x-access-token'] = melonToken;

                dispatch(throbberActions.showThrobber());

                console.log(flashcardUpdates);

                // Flashcards need to be added to the flashcard set so do it via the flashcard set api.
                axios.put(config.server_url + '/api/v1/flashcardset/flashcard/' + flashcardSetID + '/' + flashcardID,
                    { flashcardupdates :  flashcardUpdates}).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        dispatch(updateFlashcardSet(flashcardSetID, response.flashcardset));
                        resolve(response.flashcardset);

                    }
                ).catch(
                    (err)=>{

                        dispatch(throbberActions.showError(err.message));
                        reject(err.message);

                    }
                )

            }
        );

    }

}

export function deleteFlashcardRequest(flashcardSetID, flashcardID){

    return (dispatch, getState)=>{

        return new Promise(
            (resolve, reject)=>{

                const melonToken = localStorage.getItem('melon-token');
                axios.defaults.headers.common['x-access-token'] = melonToken;

                dispatch(throbberActions.showThrobber());

                // Flashcards need to be added to the flashcard set so do it via the flashcard set api.
                axios.delete(config.server_url + '/api/v1/flashcardset/flashcard/' + flashcardSetID + '/' + flashcardID).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        dispatch(updateFlashcardSet(flashcardSetID, response.flashcardset));
                        resolve(response.flashcardset);

                    }
                ).catch(
                    (err)=>{

                        dispatch(throbberActions.showError(err.message));
                        reject(err.message);

                    }
                )

            }
        );

    }

}

// Synchronous methods
export function addFlashcardSet(flashcardSet){

    return {
        type : ADD_FLASHCARDSET,
        flashcardSet
    }

}

export function updateFlashcardSet(flashcardSetID, flashcardSet){

    return {
        type : UPDATE_FLASHCARDSET,
        flashcardSetID,
        flashcardSet
    }

}

export function deleteFlashcardSet(flashcardSetID){

    return{
        type : DELETE_FLASHCARDSET,
        flashcardSetID
    }

}


// Return all flashcards to 'unediting' state
export function unsetFlashcardEditable(){

    return {
        type : UNSET_FLASHCARD_EDITABLE
    }

}

