
import * as throbberActions from './throbberActions';
import axios from 'axios';

import config from '../../../config';

const ADD_CATEGORIES = 'ADD_CATEGORIES',
    GET_CATEGORIES = 'GET_CATEGORIES',
    ADD_CATEGORY = 'ADD_CATEGORY',
    EDIT_CATEGORY = 'EDIT_CATEGORY',  // Set category as editable in the UI
    UPDATE_CATEGORY = 'UPDATE_CATEGORY',
    REMOVE_CATEGORY = 'REMOVE_CATEGORY';

export {ADD_CATEGORIES, GET_CATEGORIES, ADD_CATEGORY, EDIT_CATEGORY, UPDATE_CATEGORY, REMOVE_CATEGORY};

// Thunks - api requests related to categories
export function getCategoriesRequest(filters = {}){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return axios.get(config.server_url + '/api/v1/category', filters).then(
            (reply)=>{

                dispatch(throbberActions.hideThrobber());

                const response = reply.data;

                if(!response.success)
                    return dispatch(throbberActions.showError(response.msg));

                dispatch(addCategories(response.categories));

            }
        ).catch(
            (err)=>{

                console.error('ALARRRRMMM!! ' + err.message);

                dispatch(throbberActions.showError(err.message));

            }
        );

    }

}
// Add a new (blank) subcategory. Requires adding to the child array of this category
// and creating a new category with this one as the parent.
export function addSubcategoryRequest(parentCategoryID){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return axios.post(config.server_url + '/api/v1/category/subcat/' + parentCategoryID).then(
            (reply)=>{

                dispatch(throbberActions.hideThrobber());

                const response = reply.data;

                if(!response.success)
                    return dispatch(throbberActions.showError(response.msg));

                // Update the existing category with the new child id
                dispatch(updateCategory(response.category._id, response.category));

                // Add the child to the state
                dispatch(addCategory(response.subcategory));

            }
        ).catch(
            (err)=>{

                console.error('ALARRRRMMM!! ' + err.message);

                dispatch(throbberActions.showError(err.message));

            }
        );

    }

}

export function addCategoryRequest(category){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return axios.post(config.server_url + '/api/v1/category', {category}).then(
            (reply)=>{

                dispatch(throbberActions.hideThrobber());

                const response = reply.data;

                if(!response.success)
                    return dispatch(throbberActions.showError(response.msg));

                dispatch(addCategory(response.category));

            }
        ).catch(
            (err)=>{

                console.error('ALARRRRMMM!! ' + err.message);

                dispatch(throbberActions.showError(err.message));

            }
        );

    }

}

export function editCategoryRequest(categoryID, updatedCategory){

    return (dispatch, getState) =>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return new Promise(
            (resolve, reject)=>{

                axios.put(config.server_url + '/api/v1/category/' + categoryID, {category: updatedCategory}).then(
                    (reply)=>{

                        dispatch(throbberActions.hideThrobber());

                        const response = reply.data;

                        if(!response.success)
                            return dispatch(throbberActions.showError(response.msg));

                        dispatch(updateCategory(categoryID, response.category));
                        resolve()

                    }
                ).catch(
                    (err)=>{

                        console.error('ALARRRRMMM!! ' + err.message);
                        dispatch(throbberActions.showError(err.message));
                        reject();

                    }
                );

            }
        )


    }

}

export function deleteCategoryRequest(categoryID){

    return (dispatch, getState)=>{

        const melonToken = localStorage.getItem('melon-token');
        axios.defaults.headers.common['x-access-token'] = melonToken;

        dispatch(throbberActions.showThrobber());

        return axios.delete(config.server_url + '/api/v1/category/' + categoryID).then(
            (reply)=>{

                dispatch(throbberActions.hideThrobber());

                const response = reply.data;

                if(!response.success)
                    return dispatch(throbberActions.showError(response.msg));

                dispatch(removeCategory(categoryID));

            }
        ).catch(
            (err)=>{

                console.error('ALARRRRMMM!! ' + err.message);
                dispatch(throbberActions.showError(err.message));

            }
        );

    }

}


// Redux actions
export function addCategories(categories) {

    // Overwrite any existing categories
    return {
        type : ADD_CATEGORIES,
        categories
    }

}

export function addCategory(category){

    return {
        type : ADD_CATEGORY,
        category
    }

}

// Just sets the category as editable in the UI
export function editCategory(categoryID){

    return {
        type : EDIT_CATEGORY,
        categoryID
    }

}

export function updateCategory(categoryID, category){

    return {
        type : UPDATE_CATEGORY,
        categoryID,
        category
    }

}

export function removeCategory(categoryID){

    return{
        type : REMOVE_CATEGORY,
        categoryID
    }

}
