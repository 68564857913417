
import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import './user-dropdown.less';

export default class UserDropdown extends React.Component{

    constructor(props){

        super(props);
        this.logout = this.logout.bind(this);

    }

    logout(){

        // Delete token in local storage, update state, redirect to login page
        localStorage.removeItem('melon-token');

        this.props.userLoggedOut();
        this.props.history.push('/login');

    }

    render(){

        if(!this.props.user){
            return null;
        }

        return(
            <div className='header-content-right'>

                <div className='header-links'>

                    <Link to='/subscribe' className='btn btn-info'>Subscribe</Link>
                    <Link to='/help' className='btn btn-info'>Help / forum</Link>

                </div>

                <div className='btn-group user-dropdown'>

                    <button type="button" className="dropdown-toggle" data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                        <span className="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>
                        <span className='username'>Hello {this.props.user.firstname}</span>
                    </button>

                    <ul className="dropdown-menu">
                        <li><Link to='/account/subscriptions' className="logout-link">Your subscriptions</Link></li>
                        <li><a className="logout-link cursor-pointer" onClick={this.logout}>logout</a></li>
                    </ul>

                </div>

            </div>
        )

    }

}

UserDropdown.propTypes = {
    user : PropTypes.shape(
        { firstname : PropTypes.string.isRequired }
    )
};

