import React from 'react';

import {debounce} from '../../../lib/misc';

import {v4 as uuid} from 'uuid';

import './flashcard-display.less';

export default class FlashcardDisplay extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            editing: false,
            showQuestion: true,
            question: props.flashcard.question,
            answer: props.flashcard.answer
        };

        this.uuid = uuid();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.toggleQuestion = this.toggleQuestion.bind(this);
        this.updateFlashcard = this.updateFlashcard.bind(this);

        const classContext = this;

        this.debouncedUpdate = debounce(500,
            () => {
                classContext.props.updateFlashcard(classContext.props.flashcard._id, {
                    question: classContext.state.question,
                    answer: classContext.state.answer
                });
            }
        );

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({
            [id]: value
        });

    }

    componentWillReceiveProps(props) {

        if (!this.state.editing) {

            this.setState(
                {
                    question: props.flashcard.question,
                    answer: props.flashcard.answer
                }
            );

        }

        if (props.locked) {
            this.setState({editing: false});
        }

    }


    toggleEdit() {

        this.setState({editing: !this.state.editing});

    }

    toggleQuestion() {

        this.setState({showQuestion: !this.state.showQuestion});

    }

    updateFlashcard(ev) {

        ev.persist();
        ev.preventDefault();
        this.handleInputChange(ev);

        this.debouncedUpdate();

    }

    render() {

        return (

            <div className="col-md-4 col-sm-6 col-xs-12">

                {

                    !this.state.editing ?

                        <div id={this.uuid} className="flashcard-display" onClick={this.toggleQuestion}>

                            {/* Display flashcard */}

                            {
                                this.state.showQuestion &&
                                <span className="question-answer fade-in">
                                    {this.props.flashcard.question}
                                </span>
                            }

                            {
                                !this.state.showQuestion &&
                                <span className="question-answer fade-in">
                                    {this.props.flashcard.answer}
                                </span>
                            }

                            {
                                /* Don't show controls if the flashcard set is locked */
                                !this.props.locked ?

                                    <div className="flashcard-controls">

                                        <button
                                            onClick={() => {
                                                this.setState({editing: true})
                                            }}
                                            className="btn flashcard-btn-finished">
                                            edit
                                        </button>

                                        <div className="btn-group pull-right flashcard-delete-dropdown">

                                            <button type="button" className="dropdown-toggle" data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false">
                                    <span className="glyphicon glyphicon-trash pull-right icon-remove-flashcard"
                                          aria-hidden="true"></span>
                                            </button>

                                            <ul className="dropdown-menu flashcard-delete-confirm">
                                                <li onClick={(ev) => {
                                                    this.props.deleteFlashcard(ev, this.props.flashcard._id)
                                                }}>confirm
                                                </li>
                                            </ul>

                                        </div>

                                    </div>

                                    :

                                    null
                            }

                        </div>

                        :

                        <div id={this.uuid} className="flashcard-display">

                            {/* Edit flashcard */}

                            <div className="edit-question-answer fade-in">

                                <textarea id="question" onFocus={(ev) => {
                                    ev.target.select()
                                }}
                                          onChange={this.updateFlashcard}
                                          value={this.state.question}></textarea>

                                <textarea id="answer"
                                          onFocus={(ev) => {
                                              ev.target.select()
                                          }}
                                          onChange={this.updateFlashcard}
                                          value={this.state.answer}></textarea>

                            </div>

                            <div className="flashcard-controls">

                                <button
                                    onClick={() => {
                                        this.setState({editing: false})
                                    }}
                                    className="btn flashcard-btn-finished">
                                    finished
                                </button>

                                <div className="btn-group pull-right flashcard-delete-dropdown">

                                    <button type="button" className="dropdown-toggle" data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                    <span className="glyphicon glyphicon-trash pull-right icon-remove-flashcard"
                                          aria-hidden="true"></span>
                                    </button>

                                    <ul className="dropdown-menu flashcard-delete-confirm">
                                        <li onClick={this.removeFlashcard}>confirm</li>
                                    </ul>

                                </div>

                            </div>
                        </div>

                }

            </div>

        )

    }

}

