import React from 'react';

import {connect} from 'react-redux';

import * as throbberActions from '../../../state/actions/throbberActions';
import * as categoryActions from '../../../state/actions/categoryActions';

class EditCategory extends React.Component {

    constructor(props) {

        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateCategory = this.updateCategory.bind(this);

        this.state = {
            newName: props.category.name
        }

    }

    componentDidMount(){

        document.getElementById('editCategoryInput').focus();
        document.getElementById('editCategoryInput').select();

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    updateCategory(ev) {

        ev.preventDefault();
        this.props.editCategoryRequest(this.props.category._id, {name: this.state.newName});

    }

    render() {

        return (

            <form onSubmit={this.updateCategory}>

                <div className="form-inline edit-category-control">

                    <div className="form-group">

                        <input
                            id="editCategoryInput"
                            type="text"
                            placeholder="Type new name"
                            name="newName"
                            value={this.state.newName}
                            onBlur={this.updateCategory}
                            onChange={this.handleInputChange}
                            className="form-control"
                        />

                    </div>

                </div>


            </form>

        )

    }

}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        // You can now say this.props.throbber
        throbber: state.throbber,
        categories: state.categories,
        user: state.user
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.showThrobber
        showThrobber: () => dispatch(throbberActions.showThrobber()),
        showError: (msg) => dispatch(throbberActions.showError(msg)),
        hideThrobber: () => dispatch(throbberActions.hideThrobber()),
        editCategoryRequest: (categoryID, category) => dispatch(categoryActions.editCategoryRequest(categoryID, category))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
