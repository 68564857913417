import Immutable from 'seamless-immutable';

import {
    ADD_TAGS,
    ADD_TAG,
    EDIT_TAG,
    UPDATE_TAG,
    REMOVE_TAG,
    PROCESSING,
    PROCESSING_ERROR
} from '../actions/tagActions';

const initState = {
    processing: false,
    processingError: '',
    tags: []
};

function tags(state = initState, action) {

    const immState = Immutable(state);

    switch (action.type) {

        case ADD_TAGS:

            return {...immState, tags: action.tags}; // Just replace all of the existing tags

        case ADD_TAG :

            return {...immState, tags: [...state.tags, action.tag]};

        case UPDATE_TAG :

            const updatedTags = immState.tags.map(
                tag => {

                    if (tag._id === action.tagID) {
                        return Object.assign({}, tag, action.tagUpdates)
                    }

                    return tag;
                }
            );

            return {...immState, tags: updatedTags};

        case REMOVE_TAG:

            const filteredTags = immState.tags.filter(
                (tag) => tag._id !== action.tagID
            );

            return {...immState, tags: filteredTags};

        case PROCESSING:

            return {...immState, processing: action.isProcessing};

        case PROCESSING_ERROR:

            return {...immState, processingError: action.error};

    }

    return state;

}

export {tags}

