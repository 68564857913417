
import Immutable from 'seamless-immutable';

import {SET_USER, USER_REGISTERED, USER_LOGGED_IN, USER_LOGGED_OUT} from '../actions/userActions';

const init = null;

function user(state = init, action){

    const immState = Immutable(state);

    switch(action.type){

        case USER_REGISTERED :
        case USER_LOGGED_IN :
        case SET_USER :
            return action.user;

        case USER_LOGGED_OUT :
            return null;

    }

    return immState;

}

export {user}